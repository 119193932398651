"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.store = void 0;
const container_1 = require("./container");
const contactUs_1 = require("./contactUs");
const signup_1 = require("./signup");
const signupConfirmation_1 = require("./signupConfirmation");
const forgotPassword_1 = require("./forgotPassword");
const changePassword_1 = require("./changePassword");
const signin_1 = require("./signin");
const accountProfile_1 = require("./accountProfile");
const updatePassword_1 = require("./updatePassword");
const collegeContactUs_1 = require("./collegeContactUs");
const staffColleges_1 = require("./staffColleges");
const collegeDashboard_1 = require("./collegeDashboard");
const collegeStudents_1 = require("./collegeStudents");
const staffEmployers_1 = require("./staffEmployers");
const staffDashboard_1 = require("./staffDashboard");
const genericPageLoading_1 = require("./genericPageLoading");
const employerContactUs_1 = require("./employerContactUs");
const landingPage_1 = require("./landingPage");
const employerDetails_1 = require("./employerDetails");
const employerLinkColleges_1 = require("./employerLinkColleges");
const employerCollegesList_1 = require("./employerCollegesList");
const studentDashboard_1 = require("./studentDashboard");
const studentAssessmentsList_1 = require("./studentAssessmentsList");
const personalityAssessment_1 = require("./personalityAssessment");
const interestsAssessment_1 = require("./interestsAssessment");
const valuesAssessment_1 = require("./valuesAssessment");
const competencyAssessment_1 = require("./competencyAssessment");
const employerJobs_1 = require("./employerJobs");
const employerJobsList_1 = require("./employerJobsList");
const approveLinkedJobs_1 = require("./approveLinkedJobs");
const jobDetails_1 = require("./jobDetails");
const studentJobsList_1 = require("./studentJobsList");
const employerDashboard_1 = require("./employerDashboard");
const collegeApproval_1 = require("./collegeApproval");
const studentResumeBuilder_1 = require("./studentResumeBuilder");
const studentAppointments_1 = require("./studentAppointments");
const studentJobSearchStatus_1 = require("./studentJobSearchStatus");
const collegeStudentRecommendation_1 = require("./collegeStudentRecommendation");
const collegeJobReadiness_1 = require("./collegeJobReadiness");
const viewResume_1 = require("./viewResume");
const studentEmployersList_1 = require("./studentEmployersList");
const collegeImportStudents_1 = require("./collegeImportStudents");
const collegeDirectJobs_1 = require("./collegeDirectJobs");
const collegeStudentsSummary_1 = require("./collegeStudentsSummary");
const collegeInviteEmployers_1 = require("./collegeInviteEmployers");
const supportIssues_1 = require("./supportIssues");
const staffNotifyStudents_1 = require("./staffNotifyStudents");
const contactCareerServices_1 = require("./contactCareerServices");
const collegeEnrollmentDetails_1 = require("./collegeEnrollmentDetails");
const collegeEnrollmentList_1 = require("./collegeEnrollmentList");
const staffNotifyTpos_1 = require("./staffNotifyTpos");
const trackAccessUsers_1 = require("./trackAccessUsers");
const viewCandidates_1 = require("./viewCandidates");
const employerSignup_1 = require("./employerSignup");
const collegeManageStaff_1 = require("./collegeManageStaff");
const mentoringNotes_1 = require("./mentoringNotes");
const employerManageStaff_1 = require("./employerManageStaff");
const jobPlacementReport_1 = require("./jobPlacementReport");
const jobPlacementSummary_1 = require("./jobPlacementSummary");
const eventsWorkshops_1 = require("./eventsWorkshops");
const employerCandidateSummary_1 = require("./employerCandidateSummary");
const applyJob_1 = require("./applyJob");
const claimCollege_1 = require("./claimCollege");
const staffManageUsers_1 = require("./staffManageUsers");
const employerUsageReport_1 = require("./employerUsageReport");
const creditsUsageReport_1 = require("./creditsUsageReport");
const confirmClaimCollege_1 = require("./confirmClaimCollege");
const makePayment_1 = require("./makePayment");
const phonepePayment_1 = require("./phonepePayment");
const trackInvoicesReport_1 = require("./trackInvoicesReport");
const collegeDegreeDetails_1 = require("./collegeDegreeDetails");
const notificationPreferences_1 = require("./notificationPreferences");
const applicantCredits_1 = require("./applicantCredits");
const employabilitySkills_1 = require("./employabilitySkills");
const employerCollegeEvents_1 = require("./employerCollegeEvents");
const careerPathway_1 = require("./careerPathway");
const employerSubscription_1 = require("./employerSubscription");
const verifyCertificate_1 = require("./verifyCertificate");
const store = {
  Container: new container_1.default(),
  ContactUs: new contactUs_1.default(),
  Signup: new signup_1.default(),
  SignupConfirmation: new signupConfirmation_1.default(),
  ForgotPassword: new forgotPassword_1.default(),
  ChangePassword: new changePassword_1.default(),
  Signin: new signin_1.default(),
  AccountProfile: new accountProfile_1.default(),
  UpdatePassword: new updatePassword_1.default(),
  CollegeContactUs: new collegeContactUs_1.default(),
  StaffColleges: new staffColleges_1.default(),
  CollegeDashboard: new collegeDashboard_1.default(),
  CollegeStudents: new collegeStudents_1.default(),
  StaffEmployers: new staffEmployers_1.default(),
  StaffDashboard: new staffDashboard_1.default(),
  GenericPageLoading: new genericPageLoading_1.default(),
  EmployerContactUs: new employerContactUs_1.default(),
  LandingPage: new landingPage_1.default(),
  EmployerDetails: new employerDetails_1.default(),
  EmployerLinkColleges: new employerLinkColleges_1.default(),
  EmployerCollegesList: new employerCollegesList_1.default(),
  StudentDashboard: new studentDashboard_1.default(),
  StudentAssessmentsList: new studentAssessmentsList_1.default(),
  PersonalityAssessment: new personalityAssessment_1.default(),
  InterestsAssessment: new interestsAssessment_1.default(),
  ValuesAssessment: new valuesAssessment_1.default(),
  CompetencyAssessment: new competencyAssessment_1.default(),
  EmployerJobs: new employerJobs_1.default(),
  EmployerJobsList: new employerJobsList_1.default(),
  ApproveLinkedJobs: new approveLinkedJobs_1.default(),
  JobDetails: new jobDetails_1.default(),
  StudentJobsList: new studentJobsList_1.default(),
  EmployerDashboard: new employerDashboard_1.default(),
  CollegeApproval: new collegeApproval_1.default(),
  StudentResumeBuilder: new studentResumeBuilder_1.default(),
  StudentAppointments: new studentAppointments_1.default(),
  StudentJobSearchStatus: new studentJobSearchStatus_1.default(),
  CollegeStudentRecommendation: new collegeStudentRecommendation_1.default(),
  CollegeJobReadiness: new collegeJobReadiness_1.default(),
  ViewResume: new viewResume_1.default(),
  StudentEmployersList: new studentEmployersList_1.default(),
  CollegeImportStudents: new collegeImportStudents_1.default(),
  CollegeDirectJobs: new collegeDirectJobs_1.default(),
  CollegeStudentsSummary: new collegeStudentsSummary_1.default(),
  CollegeInviteEmployers: new collegeInviteEmployers_1.default(),
  SupportIssues: new supportIssues_1.default(),
  StaffNotifyStudents: new staffNotifyStudents_1.default(),
  ContactCareerServices: new contactCareerServices_1.default(),
  CollegeEnrollmentDetails: new collegeEnrollmentDetails_1.default(),
  CollegeEnrollmentList: new collegeEnrollmentList_1.default(),
  StaffNotifyTpos: new staffNotifyTpos_1.default(),
  TrackAccessUsers: new trackAccessUsers_1.default(),
  ViewCandidates: new viewCandidates_1.default(),
  EmployerSignup: new employerSignup_1.default(),
  CollegeManageStaff: new collegeManageStaff_1.default(),
  MentoringNotes: new mentoringNotes_1.default(),
  EmployerManageStaff: new employerManageStaff_1.default(),
  JobPlacementReport: new jobPlacementReport_1.default(),
  JobPlacementSummary: new jobPlacementSummary_1.default(),
  EventsWorkshops: new eventsWorkshops_1.default(),
  EmployerCandidateSummary: new employerCandidateSummary_1.default(),
  ApplyJob: new applyJob_1.default(),
  ClaimCollege: new claimCollege_1.default(),
  StaffManageUsers: new staffManageUsers_1.default(),
  EmployerUsageReport: new employerUsageReport_1.default(),
  CreditsUsageReport: new creditsUsageReport_1.default(),
  ConfirmClaimCollege: new confirmClaimCollege_1.default(),
  MakePayment: new makePayment_1.default(),
  PhonepePayment: new phonepePayment_1.default(),
  TrackInvoicesReport: new trackInvoicesReport_1.default(),
  CollegeDegreeDetails: new collegeDegreeDetails_1.default(),
  NotificationPreferences: new notificationPreferences_1.default(),
  ApplicantCredits: new applicantCredits_1.default(),
  EmployabilitySkills: new employabilitySkills_1.default(),
  EmployerCollegeEvents: new employerCollegeEvents_1.default(),
  CareerPathway: new careerPathway_1.default(),
  EmployerSubscription: new employerSubscription_1.default(),
  VerifyCertificate: new verifyCertificate_1.default()
};
exports.store = store;