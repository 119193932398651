import * as React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'mobx-react';
import ReactGA from "react-ga4";

import { UiTS, Constants } from 'risesharp-common';
import { store, properties } from 'risesharp-ui';

import Container from './layout/container';
import NotFound from './layout/notFound';

class Routing extends React.Component<any, any> {
  private studentUserType = Constants.STUDENTS_USER_TYPE;
  private collegeUserType = Constants.COLLEGES_USER_TYPE;
  private employerUserType = Constants.EMPLOYERS_USER_TYPE;
  private staffUserType = Constants.STAFF_USER_TYPE;

  // general urls (public access)
  private generalPathsOpen: UiTS.CustomRouteProps[] = [
    { 
      path: '/error',
      exact: true,
      componentName: 'Error',
      secure: false
    },
    { 
      path: '/error-404',
      exact: true,
      componentName: 'Error404',
      secure: false
    },
    { 
      path: '/',
      exact: true,
      componentName: 'Home',
      secure: false
    },
    { 
      path: '/about-us',
      exact: true,
      componentName: 'AboutUs',
      secure: false
    },
    { 
      path: '/contact-us',
      exact: true,
      componentName: 'ContactUs',
      secure: false
    },
    { 
      path: '/privacy-policy',
      exact: true,
      componentName: 'PrivacyPolicy',
      secure: false
    },
    { 
      path: '/terms-of-service',
      exact: true,
      componentName: 'TermsOfService',
      secure: false
    },
    { 
      path: '/forgot-password',
      exact: true,
      componentName: 'ForgotPassword',
      secure: false
    },
    { 
      path: '/change-password',
      exact: true,
      componentName: 'ChangePassword',
      secure: false
    },
    { 
      path: '/signup',
      exact: true,
      componentName: 'Signup',
      secure: false
    },
    { 
      path: '/signup-confirmation',
      exact: true,
      componentName: 'SignupConfirmation',
      secure: false
    },
    { 
      path: '/signin',
      exact: true,
      componentName: 'Signin',
      secure: false
    },
    { 
      path: '/contact-career-services',
      exact: true,
      componentName: 'ContactCareerServices',
      secure: false
    },
    { 
      path: '/referral-partner-agreement',
      exact: true,
      componentName: 'ReferralPartners',
      secure: false
    },
    { 
      path: '/job-details/:id/:college_id?',
      exact: true,
      componentName: 'JobDetails',
      secure: false
    },
    { 
      path: '/claim-college',
      exact: true,
      componentName: 'ClaimCollege',
      secure: false
    },
    { 
      path: '/confirm-claim-college',
      exact: true,
      componentName: 'ConfirmClaimCollege',
      secure: false
    },
    { 
      path: '/campus-ambassador',
      exact: true,
      componentName: 'CampusAmbassador',
      secure: false
    },
    { 
      path: '/direct-link/:code',
      exact: true,
      componentName: 'DirectLogin',
      secure: false
    },
    { 
      path: '/study-abroad',
      exact: true,
      componentName: 'StudyAbroad',
      secure: false
    },
    { 
      path: '/phonepe-payment',
      exact: true,
      componentName: 'PhonepePayment',
      secure: false
    },
    { 
      path: '/verify/:id',
      exact: true,
      componentName: 'VerifyCertificate',
      secure: false
    },
    { 
      path: '/mb-proxy',
      exact: true,
      componentName: 'MbProxy',
      secure: false
    }
  ];

  // general urls (protected access)
  private generalPaths: UiTS.CustomRouteProps[] = [
    { 
      path: '/account-profile',
      exact: true,
      componentName: 'AccountProfile',
      secure: true
    },
    { 
      path: '/update-password',
      exact: true,
      componentName: 'UpdatePassword',
      secure: true
    },
    { 
      path: '/resume/:slug/:jid?',
      exact: true,
      componentName: 'ViewResume',
      secure: false // anonymous access is also allowed
    },
    { 
      path: '/track-access-users',
      exact: true,
      componentName: 'TrackAccessUsers',
      secure: true,
      allowedUserTypes: [this.staffUserType]
    },
    { 
      path: '/view-candidates',
      exact: true,
      componentName: 'ViewCandidates',
      secure: true,
      allowedUserTypes: [this.employerUserType, this.collegeUserType]
    },
    { 
      path: '/event-workshop/:id?',
      exact: true,
      componentName: 'EventsWorkshops',
      secure: true,
      allowedUserTypes: [this.collegeUserType]
    },
    { 
      path: '/events-workshops-list',
      exact: true,
      componentName: 'EventsWorkshopsList',
      secure: true,
      allowedUserTypes: [this.collegeUserType, this.studentUserType]
    },
    { 
      path: '/view-candidate-summary',
      exact: true,
      componentName: 'ViewCandidateSummary',
      secure: true,
      allowedUserTypes: [this.employerUserType]
    },
    { 
      path: '/credits-usage-report',
      exact: true,
      componentName: 'CreditsUsageReport',
      secure: true,
      allowedUserTypes: [this.collegeUserType]
    },
    { 
      path: '/notification-preferences',
      exact: true,
      componentName: 'NotificationPreferences',
      secure: true,
      allowedUserTypes: [this.employerUserType, this.collegeUserType, this.studentUserType]
    },
    { 
      path: '/employability-skills',
      exact: true,
      componentName: 'EmployabilitySkills',
      secure: false
    },
    { 
      path: '/mktg-employability-skills',
      exact: true,
      componentName: 'EmployabilitySkills',
      secure: false
    },
    { 
      path: '/empl-skills-payment',
      exact: true,
      componentName: 'EmployabilitySkills',
      secure: false
    },
    { 
      path: '/courses/employability-skills',
      exact: true,
      componentName: 'EmployabilitySkills',
      secure: false
    },
    { 
      path: '/students/career-resources',
      exact: true,
      componentName: 'StudentResources',
      secure: true,
      allowedUserTypes: [this.studentUserType]
    },
    { 
      path: '/make-payment',
      exact: true,
      componentName: 'MakePayment',
      secure: true,
      allowedUserTypes: [this.employerUserType, this.studentUserType]
    },
  ];

  // student urls (public access)
  private studentPathsOpen: UiTS.CustomRouteProps[] = [
    { 
      path: '/students',
      exact: true,
      componentName: 'StudentsGetStarted',
      secure: false
    },
    
    { 
      path: '/students/resources/career-guides-and-tutorials',
      exact: true,
      componentName: 'StudentHowTo',
      secure: false
    },
    { 
      path: '/students/resources/job-search',
      exact: true,
      componentName: 'StudentJobSearch',
      secure: false
    },
    { 
      path: '/students/resources/career-planning',
      exact: true,
      componentName: 'StudentCareerPlanning',
      secure: false
    },
    { 
      path: '/students/resources/career-checklist',
      exact: true,
      componentName: 'StudentCareerChecklist',
      secure: false
    },
    { 
      path: '/students/resources/assessments',
      exact: true,
      componentName: 'StudentAssessments',
      secure: false
    },
    { 
      path: '/students/resources/events-workshops',
      exact: true,
      componentName: 'StudentWorkshops',
      secure: false
    },
    { 
      path: '/students/resources/career-appointment',
      exact: true,
      componentName: 'StudentResourceAppointments',
      secure: false
    },
    { 
      path: '/students/resources/jobs-internships',
      exact: true,
      componentName: 'StudentInternships',
      secure: false
    },
    { 
      path: '/students/resources/hired-quickly',
      exact: true,
      componentName: 'StudentHiredQuickly',
      secure: false
    },
    { 
      path: '/students/resources/resume-main-parts',
      exact: true,
      componentName: 'StudentResumeMainParts',
      secure: false
    },
    { 
      path: '/students/resources/resume-do-dont',
      exact: true,
      componentName: 'StudentResumeDosAndDonts',
      secure: false
    },
    { 
      path: '/students/resources/cover-letter',
      exact: true,
      componentName: 'StudentCoverLetter',
      secure: false
    },
    { 
      path: '/students/resources/dress-code',
      exact: true,
      componentName: 'StudentDressCode',
      secure: false
    },
    { 
      path: '/students/resources/ten-tips',
      exact: true,
      componentName: 'StudentTenTips',
      secure: false
    },
    { 
      path: '/students/resources/network-job',
      exact: true,
      componentName: 'StudentNetworkJob',
      secure: false
    },
    { 
      path: '/students/resources/follow-up',
      exact: true,
      componentName: 'StudentFollowUp',
      secure: false
    },
    { 
      path: '/students/resources/pre-record',
      exact: true,
      componentName: 'StudentPreRecord',
      secure: false
    },
    { 
      path: '/students/resources/phone-interview',
      exact: true,
      componentName: 'StudentPhoneInterview',
      secure: false
    },
    { 
      path: '/students/resources/common-questions',
      exact: true,
      componentName: 'StudentCommonQuestions',
      secure: false
    },
    { 
      path: '/students/resources/career-fair',
      exact: true,
      componentName: 'StudentCareerFair',
      secure: false
    },
    { 
      path: '/students/resources/tools',
      exact: true,
      componentName: 'StudentTools',
      secure: false
    }
  ];

  // student urls (protected access)
  private studentPaths: UiTS.CustomRouteProps[] = [
    { 
      path: '/students/dashboard',
      exact: true,
      componentName: 'StudentDashboard',
      secure: true
    },
    { 
      path: '/students/assessments/list/:id?',
      exact: true,
      componentName: 'StudentAssessmentsList',
      secure: true,
      allowedUserTypes: [this.studentUserType, this.collegeUserType]
    },
    { 
      path: '/students/assessments/personality/:id?',
      exact: true,
      componentName: 'PersonalityAssessment',
      secure: true,
      allowedUserTypes: [this.studentUserType, this.collegeUserType]
    },
    { 
      path: '/students/assessments/interests/:id?',
      exact: true,
      componentName: 'InterestsAssessment',
      secure: true,
      allowedUserTypes: [this.studentUserType, this.collegeUserType]
    },
    { 
      path: '/students/assessments/values/:id?',
      exact: true,
      componentName: 'ValuesAssessment',
      secure: true,
      allowedUserTypes: [this.studentUserType, this.collegeUserType]
    },
    { 
      path: '/students/assessments/competency/:student_id?/:id?',
      exact: true,
      componentName: 'CompetencyAssessment',
      secure: true,
      allowedUserTypes: [this.studentUserType, this.collegeUserType]
    },
    { 
      path: '/students/jobs-list',
      exact: true,
      componentName: 'StudentJobsList',
      secure: true,
      allowedUserTypes: [this.studentUserType]
    },
    { 
      path: '/students/resume-builder',
      exact: true,
      componentName: 'StudentResumeBuilder',
      secure: true,
      allowedUserTypes: [this.studentUserType]
    },
    { 
      path: '/students/appointments',
      exact: true,
      componentName: 'StudentAppointments',
      secure: true
    },
    { 
      path: '/students/jobsearch-status/:student_id?',
      exact: true,
      componentName: 'StudentJobSearchStatus',
      secure: true
    },
    { 
      path: '/students/view-job-readiness',
      exact: true,
      componentName: 'StudentJobReadiness',
      secure: true,
      allowedUserTypes: [this.studentUserType]
    },
    { 
      path: '/students/employers-list',
      exact: true,
      componentName: 'StudentEmployersList',
      secure: true,
      allowedUserTypes: [this.studentUserType]
    },
    { 
      path: '/students/resources/career-pathway',
      exact: true,
      componentName: 'CareerPathway',
      secure: true,
      allowedUserTypes: [this.studentUserType, this.collegeUserType]
    },
    { 
      path: '/students/apply-job/:id/:college_id?',
      exact: true,
      componentName: 'StudentApplyJob',
      secure: true,
      allowedUserTypes: [this.studentUserType]
    }
  ];

  // college urls (public access)
  private collegePathsOpen: UiTS.CustomRouteProps[] = [
    { 
      path: '/colleges',
      exact: true,
      componentName: 'CollegesGetStarted',
      secure: false
    },
    { 
      path: '/colleges/contact-us',
      exact: true,
      componentName: 'CollegeContactUs',
      secure: false
    },
    { 
      path: '/colleges/employers',
      exact: true,
      componentName: 'CollegeEmployers',
      secure: false
    }
  ];

  // college urls (protected access)
  private collegePaths: UiTS.CustomRouteProps[] = [
    { 
      path: '/colleges/students',
      exact: true,
      componentName: 'CollegeStudents',
      secure: true,
      allowedUserTypes: [this.collegeUserType]
    },
    { 
      path: '/colleges/dashboard',
      exact: true,
      componentName: 'CollegeDashboard',
      secure: true,
      allowedUserTypes: [this.collegeUserType]
    },
    { 
      path: '/approve-linked-jobs',
      exact: true,
      componentName: 'ApproveLinkedJobs',
      secure: true,
      allowedUserTypes: [this.collegeUserType, this.staffUserType]
    },
    { 
      path: '/colleges/approval',
      exact: true,
      componentName: 'CollegeApproval',
      secure: true,
      allowedUserTypes: [this.collegeUserType]
    },
    { 
      path: '/colleges/job-readiness/:id',
      exact: true,
      componentName: 'CollegeJobReadiness',
      secure: true,
      allowedUserTypes: [this.collegeUserType]
    },
    { 
      path: '/colleges/import-students/:id?',
      exact: true,
      componentName: 'CollegeImportStudents',
      secure: true,
      allowedUserTypes: [this.collegeUserType, this.staffUserType]
    },
    { 
      path: '/colleges/direct-job/:id?',
      exact: true,
      componentName: 'CollegeDirectJob',
      secure: true,
      allowedUserTypes: [this.collegeUserType]
    },
    { 
      path: '/colleges/students-summary',
      exact: true,
      componentName: 'CollegeStudentsSummary',
      secure: true,
      allowedUserTypes: [this.collegeUserType]
    },
    { 
      path: '/colleges/invite-employers',
      exact: true,
      componentName: 'CollegeInviteEmployers',
      secure: true,
      allowedUserTypes: [this.collegeUserType]
    },
    { 
      path: '/single-signup',
      exact: true,
      componentName: 'Signup',
      secure: true,
      allowedUserTypes: [this.collegeUserType]
    },
    { 
      path: '/colleges/manage-college',
      exact: true,
      componentName: 'ManageCollege',
      secure: true,
      allowedUserTypes: [this.collegeUserType]
    },
    { 
      path: '/colleges/college-widgets',
      exact: true,
      componentName: 'CollegeWidgets',
      secure: true,
      allowedUserTypes: [this.collegeUserType]
    },
    { 
      path: '/colleges/enrollment-details/:id?',
      exact: true,
      componentName: 'CollegeEnrollmentDetails',
      secure: true,
      allowedUserTypes: [this.collegeUserType]
    },
    { 
      path: '/colleges/enrollment-list',
      exact: true,
      componentName: 'CollegeEnrollmentList',
      secure: true,
      allowedUserTypes: [this.collegeUserType]
    },
    { 
      path: '/colleges/manage-staff',
      exact: true,
      componentName: 'CollegeManageStaff',
      secure: true,
      allowedUserTypes: [this.collegeUserType]
    },
    { 
      path: '/mentoring-notes/:uid?',
      exact: true,
      componentName: 'MentoringNotes',
      secure: true,
      allowedUserTypes: [this.collegeUserType, this.studentUserType]
    },
    { 
      path: '/colleges/job-placement-report',
      exact: true,
      componentName: 'JobPlacementReport',
      secure: true,
      allowedUserTypes: [this.collegeUserType]
    },
    { 
      path: '/colleges/job-placement-summary',
      exact: true,
      componentName: 'JobPlacementSummary',
      secure: true,
      allowedUserTypes: [this.collegeUserType]
    },
    { 
      path: '/colleges/mou',
      exact: true,
      componentName: 'CollegeMOU',
      secure: true,
      allowedUserTypes: [this.collegeUserType]
    }
  ];

  // employer urls (public access)
  private employerPathsOpen: UiTS.CustomRouteProps[] = [
    { 
      path: '/employers',
      exact: true,
      componentName: 'EmployersGetStarted',
      secure: false
    },
    { 
      path: '/employers/contact-us',
      exact: true,
      componentName: 'EmployerContactUs',
      secure: false
    },
    { 
      path: '/employers/signup',
      exact: true,
      componentName: 'EmployerSignup',
      secure: false
    },
    { 
      path: '/employers/signup-success',
      exact: true,
      componentName: 'EmployerSignupSuccess',
      secure: false
    }
  ];

  // employer urls (private access)
  private employerPaths: UiTS.CustomRouteProps[] = [
    { 
      path: '/employers/dashboard',
      exact: true,
      componentName: 'EmployerDashboard',
      secure: true,
      allowedUserTypes: [this.employerUserType]
    },
    { 
      path: '/employers/colleges-list',
      exact: true,
      componentName: 'EmployersCollegesList',
      secure: true,
      allowedUserTypes: [this.employerUserType]
    },
    { 
      path: '/employers/job/:id?',
      exact: true,
      componentName: 'EmployerJob',
      secure: true,
      allowedUserTypes: [this.employerUserType, this.staffUserType]
    },
    { 
      path: '/employers/jobs-list',
      exact: true,
      componentName: 'EmployerJobsList',
      secure: true,
      allowedUserTypes: [this.employerUserType]
    },
    { 
      path: '/employers/manage-staff',
      exact: true,
      componentName: 'EmployerManageStaff',
      secure: true,
      allowedUserTypes: [this.employerUserType]
    },
    { 
      path: '/employers/usage-report',
      exact: true,
      componentName: 'EmployerUsageReport',
      secure: true,
      allowedUserTypes: [this.employerUserType]
    },
    { 
      path: '/employers/college-events',
      exact: true,
      componentName: 'EmployerCollegeEvents',
      secure: true,
      allowedUserTypes: [this.employerUserType]
    }
  ];

  // staff urls
  // TO DO - change path for staff/college and staff/employer
  private staffPaths: UiTS.CustomRouteProps[] = [
    { 
      path: '/staff/dashboard',
      exact: true,
      componentName: 'StaffDashboard',
      secure: true,
      allowedUserTypes: [this.staffUserType]
    },
    { 
      path: '/staff/colleges-list',
      exact: true,
      componentName: 'StaffCollegesList',
      secure: true,
      allowedUserTypes: [this.staffUserType]
    },
    { 
      path: '/staff/college/:id?',
      exact: true,
      componentName: 'StaffCollege',
      secure: true
    },
    { 
      path: '/staff/employers-list',
      exact: true,
      componentName: 'StaffEmployersList',
      secure: true,
      allowedUserTypes: [this.staffUserType]
    },
    { 
      path: '/staff/employer/:id?',
      exact: true,
      componentName: 'StaffEmployer',
      secure: true
    },
    { 
      path: '/staff/find-student',
      exact: true,
      componentName: 'SupportIssues',
      secure: true,
      allowedUserTypes: [this.staffUserType]
    },
    { 
      path: '/staff/notify-students',
      exact: true,
      componentName: 'StaffNotifyStudents',
      secure: true,
      allowedUserTypes: [this.staffUserType]
    },
    { 
      path: '/staff/notify-tpos',
      exact: true,
      componentName: 'StaffNotifyTpos',
      secure: true,
      allowedUserTypes: [this.staffUserType]
    },
    { 
      path: '/staff/manage-users',
      exact: true,
      componentName: 'StaffManageUsers',
      secure: true,
      allowedUserTypes: [this.staffUserType]
    },
    { 
      path: '/staff/track-invoices-report',
      exact: true,
      componentName: 'TrackInvoicesReport',
      secure: true,
      allowedUserTypes: [this.staffUserType]
    },
    { 
      path: '/staff/college-degrees-list',
      exact: true,
      componentName: 'CollegeDegreeDetails',
      secure: true,
      allowedUserTypes: [this.staffUserType]
    },
    { 
      path: '/employer-subscription/:employer_id',
      exact: true,
      componentName: 'EmployerSubscription',
      secure: true,
      allowedUserTypes: [this.staffUserType]
    }
  ];

  // redirect mapping
  private redirectPaths: UiTS.CustomRouteProps[] = [
    // forgot password>change password
    {
      path: '/p/:slug',
      exact: true,
      componentName: 'UrlShortener',
      secure: false
    },
    // new student account
    {
      path: '/a/:slug',
      exact: true,
      componentName: 'UrlShortener',
      secure: false
    },
    // job notifications
    {
      path: '/j/:slug',
      exact: true,
      componentName: 'UrlShortener',
      secure: false
    },
    // direct link
    {
      path: '/d/:slug',
      exact: true,
      componentName: 'UrlShortener',
      secure: false
    }
  ];

  // wildcard mapping
  /* IMPORTANT -wildcard mapping must always appear at the end */
  private wildcardPaths: UiTS.CustomRouteProps[] = [
    { 
      path: '/colleges/:slug',
      exact: true,
      componentName: 'CollegeProfile',
      secure: false
    },
    { 
      path: '/employers/:slug',
      exact: true,
      componentName: 'EmployerProfile',
      secure: false
    }
  ];

  private paths: UiTS.CustomRouteProps[] = this.generalPathsOpen.concat(
    this.generalPaths,
    this.studentPathsOpen,
    this.studentPaths,
    this.collegePathsOpen,
    this.collegePaths, 
    this.employerPathsOpen,
    this.employerPaths, 
    this.staffPaths,
    this.redirectPaths,
    this.wildcardPaths
  );

  // see key issue forcing remount with key={idx} (RRD issue 3928)
  // https://github.com/ReactTraining/react-router/issues/3928
  // also https://github.com/ReactTraining/react-router/issues/1487
  private routes: JSX.Element[] = 
    this.paths.map((obj: UiTS.CustomRouteProps, idx: number) => (
      <Route 
        key={0}
        path={obj.path} 
        exact={obj.exact} 
        render={(props) => (
          <Container {
            ...Object.assign(
              props, 
              { 
                componentName: obj.componentName, 
                secure: obj.secure, 
                allowedUserTypes: obj.allowedUserTypes 
              }
            )
          }/>
        )}
      />
    )
  );

  constructor(props: React.Props<any>) {
    super(props);
    this.loadGA();
  }

  render() {
    return (
      <Provider store={store}>
        <Router>
          <Switch>
            {this.routes}
            <Route component={NotFound}/>
          </Switch>
        </Router>
      </Provider>
    );
  }

  private loadGA() {
    const gaTrackingId = properties.google_analytics_id;
    if (gaTrackingId) {
      ReactGA.initialize(gaTrackingId);
    }
  }
}

export default Routing;
