"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const lodash_1 = require("lodash");
const mobx_1 = require("mobx");
const risesharp_common_1 = require("risesharp-common");
const base_1 = require("./base");
/*
* IMPORTANT - does not entirely use Base functions
*/
class EmployabilitySkills extends base_1.Base {
  constructor() {
    super({});
    this.resetState = mobx_1.action(() => {
      this.resetObservable();
    });
    this.setCustomApiState = mobx_1.action(newState => {
      const api = this.state.api;
      const errorMessage = lodash_1.get(newState, 'errorMessage');
      if (errorMessage !== undefined) {
        api.errorMessage = errorMessage;
      }
      const successMessage = lodash_1.get(newState, 'successMessage');
      if (successMessage !== undefined) {
        api.successMessage = successMessage;
      }
      const employabilitySkillsData = lodash_1.get(newState, 'employabilitySkills');
      if (employabilitySkillsData !== undefined) {
        const client = this.state.client;
        const count = lodash_1.get(employabilitySkillsData, 'count');
        if (count !== undefined) {
          api.data.countDetails = count;
          if (count.length === 1) {
            const skillsCompleted = count[0].skillsCompleted;
            const modulesCompleted = count[0].modulesCompleted;
            const topicsCompleted = count[0].topicsCompleted;
            Object.keys(skillsCompleted).forEach(skillId => {
              const tmpArr = skillsCompleted[skillId];
              if (tmpArr.length === 2) {
                client.completionTracker.set('skill_id____' + skillId, tmpArr);
              }
            });
            Object.keys(modulesCompleted).forEach(moduleId => {
              const tmpArr = modulesCompleted[moduleId];
              if (tmpArr.length === 2) {
                client.completionTracker.set('module_id____' + moduleId, tmpArr);
              }
            });
            Object.keys(topicsCompleted).forEach(topicId => {
              client.completionTracker.set('topic_id____' + topicId, topicsCompleted[topicId]);
            });
          }
        }
        const skills = lodash_1.get(employabilitySkillsData, 'skills');
        if (skills !== undefined) {
          const {
            undiscountedPayment,
            paymentRequired,
            paymentRequiredSignature,
            records,
            tentipsVideoUrl
          } = skills;
          api.data.undiscountedPayment = undiscountedPayment;
          api.data.paymentRequired = paymentRequired;
          api.data.paymentRequiredSignature = paymentRequiredSignature;
          api.data.skillsList = records;
          if (records.length > 0) {
            client.current_skill = records[0].id;
            client.skill_name = records[0].name;
          } else {
            client.current_skill = '';
            client.skill_name = '';
          }
          // update video map urls
          if (records.length > 0) {
            for (const x of records) {
              const urls = x.s3_urls;
              if (urls.length > 0) {
                for (const y of urls) {
                  if (this.state.client.videoMap.has(y.identifier)) {
                    this.state.client.videoMap.set(y.identifier, y.url);
                  }
                }
              }
            }
          }
          if (tentipsVideoUrl) {
            client.tentipsVideoUrl = tentipsVideoUrl;
          }
        }
        const modules = lodash_1.get(employabilitySkillsData, 'modules');
        if (modules !== undefined) {
          api.data.modulesList = modules;
          if (modules.length > 0) {
            client.current_module = modules[0].id;
            client.module_name = modules[0].name;
          } else {
            client.current_module = '';
            client.module_name = '';
          }
        }
        const topics = lodash_1.get(employabilitySkillsData, 'topics');
        if (topics !== undefined) {
          api.data.topicsList = topics;
          if (topics.length > 0) {
            client.current_topic = topics[0].id;
          } else {
            client.current_topic = '';
          }
        }
      }
    });
    this.resetCustomApiState = mobx_1.action(() => {
      const api = this.state.api;
      api.errorMessage = '';
      api.successMessage = '';
    });
    this.resetState();
  }
  setCustomGenericError() {
    const api = this.state.api;
    api.errorMessage = risesharp_common_1.Constants.GENERIC_ERROR;
  }
  resetObservable() {
    mobx_1.extendObservable(this, {
      state: Object.assign(this.state, this.getDefaultState())
    });
  }
  getDefaultState() {
    return {
      client: {
        view_type: 'skill',
        skill_id: '',
        skill_name: '',
        module_id: '',
        module_name: '',
        topic_id: '',
        current_skill: '',
        current_module: '',
        current_topic: '',
        completionTracker: new mobx_1.ObservableMap(),
        email_or_mobile: '',
        loop_counter: 0,
        videoMap: this.getVideoMap(),
        descMap: this.getDescMap(),
        tentipsVideoUrl: ''
      },
      api: {
        errorMessage: '',
        successMessage: '',
        data: {
          countDetails: [],
          undiscountedPayment: 0,
          paymentRequired: 0,
          paymentRequiredSignature: '',
          skillsList: [],
          modulesList: [],
          topicsList: []
        }
      },
      spinner: {
        [risesharp_common_1.Constants.IDENTIFIER_COMPONENTMOUNT]: false
      }
    };
  }
  getVideoMap() {
    const videoMap = new mobx_1.ObservableMap();
    videoMap.set('TOPICS_COMMN_WRITS_ESSAY', '');
    videoMap.set('TOPICS_COMMN_WRITS_GI', '');
    videoMap.set('TOPICS_COMMN_WRITS_GIC', '');
    videoMap.set('TOPICS_COMMN_WRITS_WDAD', '');
    videoMap.set('TOPICS_COMMN_WRITS_EMETQ', '');
    videoMap.set('TOPICS_COMMN_INTRP_SKILLS', '');
    videoMap.set('TOPICS_COMMN_INTRP_STYLES', '');
    videoMap.set('TOPICS_COMMN_INPLS_LISS', '');
    videoMap.set('TOPICS_COMMN_INPEX_EXP', '');
    videoMap.set('TOPICS_COMMN_INPEX_MATCH', '');
    videoMap.set('TOPICS_COMMN_INPEX_POWER', '');
    videoMap.set('TOPICS_COMMN_INPEX_LANG', '');
    videoMap.set('TOPICS_COMMN_INPVR_ROLE', '');
    videoMap.set('TOPICS_COMMN_INPVR_TONE', '');
    videoMap.set('TOPICS_COMMN_INPNV_BODY', '');
    videoMap.set('TOPICS_COMMN_INPNV_EMOT', '');
    videoMap.set('TOPICS_PROFW_MLAH_SWOT', '');
    videoMap.set('TOPICS_PROFW_MLAH_GROWTH', '');
    videoMap.set('TOPICS_PROFW_MLAH_LEARN', '');
    videoMap.set('TOPICS_PROFW_AWC_ATTD', '');
    videoMap.set('TOPICS_PROFW_AWC_ETQ1', '');
    videoMap.set('TOPICS_PROFW_AWC_ETQ2', '');
    videoMap.set('TOPICS_PROFW_PERAP_DRESS', '');
    videoMap.set('TOPICS_PROFW_PERAP_GROOM', '');
    videoMap.set('TOPICS_PROFW_INHON_HON', '');
    videoMap.set('TOPICS_PROFW_INHON_INTG', '');
    videoMap.set('TOPICS_PROFW_INHON_TIME', '');
    videoMap.set('TOPICS_PROFW_PEACC_PRIR', '');
    videoMap.set('TOPICS_PROFW_PEACC_GIVE', '');
    videoMap.set('TOPICS_PROFW_PEACC_RECV', '');
    videoMap.set('TOPICS_PROFW_PEACC_SMART', '');
    videoMap.set('TOPICS_PROFW_PEACC_DUMB', '');
    videoMap.set('TOPICS_DIGIC_MEETS_VMEET', '');
    videoMap.set('TOPICS_DIGIC_MEETS_PVMEET', '');
    videoMap.set('TOPICS_DIGIC_FOOTP_LIP1', '');
    videoMap.set('TOPICS_DIGIC_FOOTP_LIP2', '');
    videoMap.set('TOPICS_DIGIC_FOOTP_LIMC', '');
    videoMap.set('TOPICS_DIGIC_PORTF_IMPR', '');
    videoMap.set('TOPICS_DIGIC_PORTF_TOOLS', '');
    videoMap.set('TOPICS_DIGIC_PORTF_EXAMP', '');
    videoMap.set('TOPICS_DIGIC_TOOLS_INTRO', '');
    videoMap.set('TOPICS_DIGIC_TOOLS_BEST1', '');
    videoMap.set('TOPICS_DIGIC_TOOLS_BEST2', '');
    videoMap.set('TOPICS_DIGIC_TOOLS_PICKT', '');
    videoMap.set('TOPICS_TEAMW_WTEAM_IMPTW', '');
    videoMap.set('TOPICS_TEAMW_WTEAM_COLLB', '');
    videoMap.set('TOPICS_TEAMW_WTEAM_ADAPT', '');
    videoMap.set('TOPICS_TEAMW_WTEAM_BUILR', '');
    videoMap.set('TOPICS_TEAMW_TEAML_LEADS', '');
    videoMap.set('TOPICS_TEAMW_TEAML_FACSK', '');
    videoMap.set('TOPICS_TEAMW_TEAML_DELEG', '');
    videoMap.set('TOPICS_TEAMW_TEAML_DELVR', '');
    videoMap.set('TOPICS_TEAMW_CONFR_RESOL', '');
    videoMap.set('TOPICS_TEAMW_CONFR_DVSD', '');
    videoMap.set('TOPICS_TEAMW_CONFR_EMOTN', '');
    videoMap.set('TOPICS_TEAMW_CONFR_SUPPE', '');
    videoMap.set('TOPICS_CRITT_PROBS_IDENT', '');
    videoMap.set('TOPICS_CRITT_PROBS_EVAL', '');
    videoMap.set('TOPICS_CRITT_PROBS_ROOT', '');
    videoMap.set('TOPICS_CRITT_CREAT_SIXH', '');
    videoMap.set('TOPICS_CRITT_CREAT_MIND', '');
    videoMap.set('TOPICS_CRITT_CREAT_BRAIN', '');
    videoMap.set('TOPICS_CRITT_ANLYS_HOWTO', '');
    videoMap.set('TOPICS_CRITT_ANLYS_WHAT', '');
    videoMap.set('TOPICS_CRITT_ANLYS_REAS', '');
    videoMap.set('TOPICS_CRITT_ANLYS_AFTER', '');
    return videoMap;
  }
  getDescMap() {
    const descMap = new Map();
    // skill - communication
    descMap.set('SKILLS_COMMN', "Effective communication is critical for success in any situation, whether at workplace or otherwise. Inadequate communication can be damaging to an organization and hence employers place a premium on a candidate\u2019s ability to communicate. Effective communication does not simply imply using appropriate language; it extends to how you interact with yourself and how attentively you listen to others. The capacity to communicate effectively with others, to put them at ease, and to earn their trust and confidence is critical to the success of the organization you represent. The following modules will familiarize you with many aspects of communication skills that will assist you in becoming job ready.");
    descMap.set('MODULES_COMMN_WRITS', "Writing skills are critical for any professional, regardless of the nature of the job. They will assist you in adequately conveying your ideas and views. By developing your writing skills, you will gain self-sufficiency, as you will be able to write letters, articles, and meeting notes on your own. Additionally, they may establish you as a valuable team member by allowing others to rely on you for reports, suggestions, and so forth.");
    descMap.set('MODULES_COMMN_INTRP', "Intrapersonal skills are mostly concerned with self-awareness and self-control. Your intrapersonal abilities assist you in more effectively managing your interpersonal relationships. These are not academic abilities. Rather than that, they are the result of continuous self-reflection and revision of the way you portray yourself to the world. Intrapersonal competencies, like technical competencies, are critical professional tools. Intrapersonal abilities empower you to take control of your feelings and emotions. This type of thinking is referred to as intrapersonal intelligence. You develop the ability to direct your attention, establish priorities and objectives, and think critically and creatively. When events do not unfold as anticipated, you are prepared to respond intelligently and intentionally.");
    descMap.set('MODULES_COMMN_INPLS', "Listening is a soft skill that enables people to comprehend one another. It is a component of interpersonal communication abilities such as speaking and conversing.  Effective listening is necessary for workplace success. They contribute to job performance, collaboration, and customer service.  To completely comprehend a manager's expectations, it is necessary to listen closely. Your ability to work improves.  Strong partnerships begin with effective communication, particularly active listening. Those who are employed are no different. Comprehending your coworkers enables you to develop rapport. This is necessary for group work.");
    descMap.set('MODULES_COMMN_INPEX', "In social contexts, expression is critical. This is a skill that, if acquired, will assist you in navigating a variety of job settings. When one person listens to another , a highly creative process occurs in which the listener mentally reconstructs the speaker's experience. The manner in which you express yourself has a direct impact on the profundity of your words and the true content of your message.");
    descMap.set('MODULES_COMMN_INPVR', "Verbal communication has two main stakeholders- \u201Ctone\u201D and \u201Cwords\u201D. \n      <br><br>\n      When you talk to other people, your tone allows them to understand you. There are many different ways to say a phrase. In the workplace, your tone can not only change how people see you but also how willing they are to listen to you. \n      <br><br>\n      Words can be very powerful. They can be used to motivate, provide direction, and even bring about peace. When utilised improperly, they can be used to incite conflict and create fear.\n      <br><br>\n      This session teaches you how to choose the appropriate tone and phrases for any given occasion.\n      ");
    descMap.set('MODULES_COMMN_INPNV', "Did you know? approximately 93 percent of communication is nonverbal, while words account for only 7 percent. \n      <br><br>\n      You may find yourself exchanging nonverbal signals with coworkers all day long at work. Body language and facial emotions are powerful tools for reinforcing and enhancing workplace discussions. Nonverbal communication has an impact on every contact, whether it's a presentation at a conference, a conversation with a coworker in the corridor, or a meeting with your boss.\n      This module will assist you in honing your nonverbal communication abilities for corporate communication.\n      ");
    // skill - pwe
    descMap.set('SKILLS_PROFW', "Professionalism and ethical behaviour are critical components of workplace success. It assists you in establishing a positive reputation and value among your coworkers. Employers value and reward individuals who exhibit personal accountability and successful work practises. Professionalism and solid work ethics are easy to establish during college. Punctuality, collaboration with others, time management, and integrity and ethical behaviour are only a few examples. The following lessons are designed to familiarise you with various facets of professionalism and work ethics and to provide guidance on how to adopt them.");
    descMap.set('MODULES_PROFW_MLAH', "Transitioning from college to corporate life is, in fact, a complicated and difficult process. There are no prescribed lesson plans. However, you must keep in mind that your educational journey does not finish when you graduate from college. Indeed, it becomes more pronounced as you climb up the corporate ladder. This includes developing new skills, improving capabilities, being relevant with the industry trends, and learning new tools. Additionally, it  also includes improving one's soft skills and putting everything together. This module explains how corporate learning works and how you can become a lifelong student.");
    descMap.set('MODULES_PROFW_AWC', "Although the phrase \"company culture\" is rather vague, most culture professionals can agree on the fundamentals of a definition. In a nutshell, corporate culture is defined as an organization's common set of values, goals, attitudes, and behaviours. It is entirely up to an organisation to define its own culture. This module will give you a gist of how to adjust into a company\u2019s work culture.");
    descMap.set('MODULES_PROFW_PERAP', "Each workplace has its own set of standards and procedures. Personal hygiene and cleanliness are always incorporated into these standards and rules. Each firm has its own dress code. This dress code is part of the employees' self-hygiene. Maintaining adequate hygiene in the workplace is critical for a business's reputation to remain positive. Both the governmental authorities and employees are responsible for maintaining hygiene. No one would want to work in an organisation that had filthy restrooms, cubicles, and an unsanitary work atmosphere. Similarly, no employer would want an unhygienic or a unpleasantly employee to represent his company in the market or in public.");
    descMap.set('MODULES_PROFW_INHON', "It's unsurprising that, of all the personality development abilities, honesty and integrity are the most critical. While your performance in work is an important factor for excelling you career, it is these traits that enhance your performance. Employers too keenly observe these attributes of yours to understand your credibility as a professional. It increases the likelihood of a regular employee being promoted or succeeding in his present project if he demonstrates such attitude towards his performance. It is to be candid with facts, thoughts, and mind maps, and to be receptive to constructive criticism.");
    descMap.set('MODULES_PROFW_PEACC', "In the job, accountability means showing up and keeping your obligations. It is about accepting responsibility for one's job on a personal level. It's also about instilling confidence in your teammates by demonstrating that they can rely on you. Accountability strengthens professional relationships, boosts job satisfaction, and supports in professional development. This module will assist you in accepting responsibility for not only the tasks at hand, but also for your entire professional growth and development.");
    // skill_digic
    descMap.set('SKILLS_DIGIC', "Almost all jobs require a level of digital expertise, and the need to be digitally competent will\n      certainly continue to increase as the world becomes more digital. Being digitally competent\n      means that you have the knowledge, skill and ability when using technology to be competent at the tasks you are responsible for in your job. Through the use of technology, you are able to solve problems, communicate, collaborate, and manage information required to be successful.");
    descMap.set('MODULES_DIGIC_MEETS', "One of the main aspects of Digital Competence are virtual meetings. As simple as they may appear, Virtual meetings can define a lot about your personality. Besides, you must be aware of some steps that you could take before, during and after the meeting in order to make the most of every interaction. This module talks about how you can conduct yourself and the steps you need to take to ensure effectiveness of a meeting.");
    descMap.set('MODULES_DIGIC_FOOTP', "In the digital age, an online presence has become unavoidable. It is critical to remember that whatever we do on the internet might leave a trail of data in our wake. This information has a significant impact on our reputation. As a professional or as someone just starting out in your career, you must ensure that you are taking advantage of the online opportunities for professional development that are available to you. Simultaneously, you must be cognizant of our online presence. This module discusses how to make the most of professional networking platforms. The module also talks about what not to do in order to keep your professional reputation clean.");
    descMap.set('MODULES_DIGIC_PORTF', "Creating a good portfolio is critical for every student. A portfolio showcases your abilities and demonstrates your expertise. This can help you in not just getting internships and other work opportunities, but is also essential for your further studies.  However, you do not need years of experience or hundreds of completed projects to build an impressive portfolio as a student! Simple experiences, such as volunteering or membership in a student organisation, can be added to your portfolio. This moudule takes you step-by-step through the process of developing a portfolio, beginning in your first year.");
    descMap.set('MODULES_DIGIC_TOOLS', "Tools have become indispensable in virtually every sector of work. Every organisation makes use of tools in some capacity. To quickly adapt to new tools, one must be technologically knowledgeable. That is why it is critical to begin investigating tools at a young age. As a student, tools can be your best friends when it comes to homework, assignments, productivity, and virtually every other part of your life. They can significantly simplify and enhance your life. This section discusses how to recognise tools. At the conclusion of the module, you will be able to choose at least 2-3 tools for everyday use.");
    // skills_teamw
    descMap.set('SKILLS_TEAMW', "Working in a team is essential for both the success of an organization and your own personal development. Everyone on the team has the opportunity to learn from and teach one another while working together. Working with people from diverse backgrounds can provide you with new perspectives. The term \"leadership\" is often used interchangeably with \"supervising others.\" However, being a boss is not a prerequisite for being a leader. Leaders aren't defined by their titles. In order to be a leader, you must help others reach a common goal. The ability to motivate, inspire, and encourage others to work toward a common goal is often considered a leadership quality. Any team, whether it's a cricket team or a project team, will have disagreements. When people have differing opinions, lack respect for each other, or misread each other's intentions, conflict is likely to arise. You must be able to approach a problem with a good attitude and common sense as a young professional.");
    descMap.set('MODULES_TEAMW_WTEAM', "Working in a team is essential for both the success of an organization and your own personal development. Everyone on the team has the opportunity to learn from and teach one another while working together. Working with people from diverse backgrounds can provide you with new perspectives. Additionally, you can learn from the blunders of others, which can save you from repeating the same mistakes. This module introduces you to the various stakeholders that would require you to transition into a team player.");
    descMap.set('MODULES_TEAMW_TEAML', "The term \"leadership\" is often used interchangeably with \"supervising others.\" However, being a boss is not a prerequisite for being a leader. Leaders aren't defined by their titles. In order to be a leader, you must help others reach a common goal. The ability to motivate, inspire, and encourage others to work toward a common goal is often considered a leadership quality. This module helps you identify your unique strengths that would help you evolve into a great leader.");
    descMap.set('MODULES_TEAMW_CONFR', "Any team, whether it's a cricket team or a project team, will have disagreements. When people have differing opinions, lack respect for each other, or misread each other's intentions, conflict is likely to arise. You must be able to approach a problem with a good attitude and common sense as a young professional. Identifying and resolving workplace problems without jeopardising the team's productivity will be the focus of this module, which explores the causes and consequences of workplace conflict.");
    // skill_critt
    descMap.set('SKILLS_CRITT', "One of the most crucial learning objectives is to develop students' critical thinking skills, which involve encouraging them to question and evaluate their own assumptions and assumptions made in light of the information they have been given. Students who have been assigned an assignment and are tasked with conducting extensive research on the issue at hand can benefit greatly from developing their critical thinking skills. In the long run, it benefits one's career as well.<br><br>In its simplest form, critical thinking is the capacity to comprehend information and to question the likely outcomes of proposed courses of action. Since today's students have grown up in a world where instantaneous access to vast amounts of information is taken for granted, it is crucial that they develop the ability to critically assess the validity of the claims they encounter in their academic and social environments and spot propaganda beneath the surface.");
    descMap.set('MODULES_CRITT_PROBS', "As we all experience challenges on a regular basis, everyone can benefit from having solid problem-solving skills. Clearly, some of these issues are more serious or complex than others. \n      It would be ideal to be able to address all problems in a fast and efficient manner without any difficulties; but, there is no single method for doing so.<br><br>Here are some techniques that would help you become a master problem solver.");
    descMap.set('MODULES_CRITT_CREAT', "Creativity is beneficial to you not only as an individual, but also as a professional. You can use your own or others' creativity to create fresh answers to old problems, foster teamwork, and develop more efficient processes for your organization's operations. In this essay, we explore how creative people think, the various aspects of creative skill, and the crucial role creativity plays in the workplace.");
    descMap.set('MODULES_CRITT_ANLYS', "Ability to gather data and examine it carefully are the hallmarks of analytical thinking. They are the abilities to analyse a situation, formulate a plan of action, then implement that plan based on the knowledge gained from that analysis.<br><br>Finding patterns, coming up with ideas, observing data, collecting data, analysing data, incorporating new information, synthesising information, and making situation-appropriate decisions all require analytical abilities.<br><br>The ability to analyse a situation, determine the best course of action, then implement that plan is crucial in today's world. The upcoming courses are structured to facilitate the sameThe upcoming modules have been carefully designed to help you develop the same..");
    return descMap;
  }
}
exports.default = EmployabilitySkills;