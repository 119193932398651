import * as React from 'react';
import { inject, observer } from 'mobx-react';

import { 
  Signout as Service, 
  MaintenanceMessages as MaintMessageService,
  CareerPathway as TaskService,
  EmployabilitySkills as EmployabilitySkillsService,
  MiscDetails as MiscDetailsService
} from 'risesharp-ui';
import { UiTS, Constants } from 'risesharp-common';

import { Base } from './../parentClasses/base';
import { Helper } from './../utils/helper';
import { ServiceInput } from './../utils/serviceInput';

// require only after import
const isOnline = require('is-online');
const logo = require('./../images/gjr_white.png');

@inject('store')
@observer
class Header extends Base {
  constructor(props: React.Props<any>) {
    super(props);
    super.initStore(this.props.store.Container);
    this.handleLoad = this.handleLoad.bind(this);
    this.showPage = this.showPage.bind(this);
  }

  componentDidMount() {
    // need to initialize state from localStorage and store in container store
    const userDetails = Helper.getInitStateAuthorizedUserDetails();
    const collegeDetails = Helper.getInitStateCollegeDetails();
    const employerDetails = Helper.getInitStateEmployerDetails();
    this.getStore().loadState(userDetails, collegeDetails, employerDetails);

    // api calls
    const store = this.props.store.Container;
    this.loadBannerDetails(store);
  
    window.addEventListener('load', this.handleLoad);
  }

  // tslint:disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    const store = nextProps.store.Container;
    this.loadBannerDetails(store);
  }
  
  componentWillUnmount() {
    if (this.getState().internetChkIntervalId > 0) {
      window.clearInterval(this.getState().internetChkIntervalId);
    }
    if (this.getState().maintMsgIntervalId > 0) {
      window.clearInterval(this.getState().maintMsgIntervalId);
    }
    if (this.getState().studentTaskIntervalId > 0) {
      window.clearInterval(this.getState().studentTaskIntervalId);
    }
    if (this.getState().studentEmplSkillsIntervalId > 0) {
      window.clearInterval(this.getState().studentEmplSkillsIntervalId);
    }
    if (this.getState().checkLogoutIntervalId > 0) {
      window.clearInterval(this.getState().checkLogoutIntervalId);
    }
  }

  render() {
    const hideHeaderAndFooter = this.props.hideHeaderAndFooter || false;
    if (!hideHeaderAndFooter) {
      return (
        <React.Fragment>
          {/*main header*/}
          <div className="main-header">
            <div className="container">
              <nav className="navbar navbar-expand-lg navbar-light bg-light">
                {/* main logo */}
                {this.displayMainLogo()}
                {/* offline */}
                {this.displayOfflineLogo()}
                {/* only mobile devices - start */}
                {/* #####anonymous user##### */}
                {(
                  !this.getState().isUserLoggedIn &&
                  <div className="d-lg-none ml-auto mx-2">
                    <span className={'nav-btn ' + (this.checkCurrentRoute('/signin') ? 'active' : '')}><a role="button" href="/signin" className="nav-link btn btn-link buttonAsLinkMenu" onClick={(e) => this.showPage(e, '/signin')}>Login</a></span>
                  </div>
                )}
                {/* #####authenticated user##### */}
                {(
                  this.getState().isUserLoggedIn &&
                  <div className="d-lg-none ml-auto mx-2">
                    <span className="nav-btn"><a role="button" href="/signout" className="dropdown-item btn btn-link buttonAsLinkMenu" onClick={(e) => this.signoutEvent(e)}>Signout</a></span>
                  </div>
                )}
                <button className="navbar-toggler" type="button" data-target="#navbarSupportedContent" onClick={this.toggleNavEvent}>
                  <span className="nav-custom-icon">
                    <i className="fa fa-lg fa-bars">
                      &nbsp;Menu
                    </i>
                  </span>
                </button>
                {/* only mobile devices - end */}
                {/* navbar */}
                <div className={(this.getState().isToggleMenuOpen ? '' : 'collapse') + ' navbar-collapse'} id="navbarSupportedContent">
                  <ul className="navbar-nav ml-auto my-auto">
                    {/* #####anonymous user##### */}
                    {(
                      !this.getState().isUserLoggedIn &&
                      this.displayAnonymousAccess()
                    )}
                    {/* #####authenticated user##### */}
                    {/* common/1 */}
                    {(
                      this.getState().isUserLoggedIn &&
                      <React.Fragment>
                        {/* dashboard */}
                        {this.displayDashboardLink()}
                      </React.Fragment>
                    )}
                    {/* staff user */}
                    {this.displayStaffMenu()}
                    {/* student user */}
                    {this.displayStudentMenu()}
                    {/* college user */}
                    {this.displayCollegeUserMenu()}
                    {/* employer user */}
                    {this.displayEmployerUserMenu()}
                    {/* common/2 */}
                    {this.displayDropdownMenu()}
                    {/* common/3 */}
                    {this.handleDropdownDisplay()}
                  </ul>
                </div>
              </nav>
            </div>
          </div>
          {/*maint. message*/}
          {this.displayMaintenanceMessage()}
        </React.Fragment>
      );
    }
    return (<div className="main-header"></div>);
  }

  // display sections
  private displayMainLogo() {
    let homeUrl = '/';
    if (this.getState().isUserLoggedIn) {
      homeUrl = this.getHomeUrl();
    }
    return (
      <a role="button" href={homeUrl} className="navbar-brand" onClick={(e) => this.showPage(e, homeUrl)}>
      <img src={logo} className="img-fluid" alt=""/>
      </a>
    );
  }

  private displayOfflineLogo() {
    if (!this.getState().isInternetOnline) {
      return (
        <span className="offline-warning text-warning">
          <i className="fa fa-warning offline-warning-i" title="Offline"></i>
        </span>
      );
    }
    return null;
  }

  private displayAnonymousAccess() {
    return (
      <React.Fragment>
        <li className={'nav-item ' + (this.checkCurrentRoute('/students') ? 'active' : '')}>
          <a role="button" href="/students" className="nav-link btn btn-link buttonAsLinkMenu" onClick={(e) => this.showPage(e, '/students')}>
            Career Pathway
          </a>
        </li>
        <li className={'nav-item ' + (this.checkCurrentRoute('/employability-skills') ? 'active' : '')}>
          <a role="button" href="/employability-skills" className="nav-link btn btn-link buttonAsLinkMenu" onClick={(e) => this.showPage(e, '/employability-skills')}>
            Employability Skills
          </a>
        </li>
        <li className={'nav-item ' + (this.checkCurrentRoute('/students/jobs-list') ? 'active' : '')}>
          <a role="button" href="/students/jobs-list" className="nav-link btn btn-link buttonAsLinkMenu" onClick={(e) => this.showPage(e, '/students/jobs-list')}>
            Job Alerts
          </a>
        </li>        
        <li className={'nav-item ' + (this.checkCurrentRoute('/campus-ambassador') ? 'active' : '')}>
          <a role="button" href="/campus-ambassador" className="nav-link btn btn-link buttonAsLinkMenu" onClick={(e) => this.showPage(e, '/campus-ambassador')}>Campus Ambassador</a>
        </li>
        <li className={'nav-btn ' + (this.checkCurrentRoute('/signin') ? 'active d-none d-lg-block' : 'd-none d-lg-block')}>
          <a
            role="button"
            href="/signin"
            className="nav-link btn btn-link buttonAsLinkMenu"
            onClick={(e) => this.showPage(e, '/signin')}
          >
            Login
          </a>
        </li>
      </React.Fragment>
    );
  }

  private displayDashboardLink() {
    return (
      <li className={'nav-item ' + (this.checkCurrentRoute(this.getHomeUrl()) ? 'active' : '')}>
        <a role="button" href={this.getHomeUrl()} className="nav-link btn btn-link buttonAsLinkMenu" onClick={(e) => this.showPage(e, this.getHomeUrl())}>
          Dashboard
        </a>
      </li>
    );
  }

  private displayStaffMenu() {
    if (this.getState().isUserLoggedIn &&
    Helper.checkActiveUserType(this.props, Constants.STAFF_USER_TYPE)) {
      const adminMenu = this.hasStaffSuperAdminPrivileges() || this.hasStaffAdminPrivileges();
      const bdmCollegeMenu = this.hasStaffBDMCollegePrivileges();
      const bdmEmployerMenu = this.hasStaffBDMEmployerPrivileges();
      return (
        <React.Fragment>
          {(
            (adminMenu || bdmCollegeMenu) &&
            <li className={'nav-item ' + (this.checkCurrentRoute('/staff/colleges-list') ? 'active' : '')}>
              <a role="button" href="/staff/colleges-list" className="nav-link btn btn-link buttonAsLinkMenu" onClick={(e) => this.showPage(e, '/staff/colleges-list')}>
                Colleges
              </a>
            </li>
          )}
          {(
            (adminMenu || bdmEmployerMenu) &&
            <li className={'nav-item ' + (this.checkCurrentRoute('/staff/employers-list') ? 'active' : '')}>
              <a role="button" href="/staff/employers-list" className="nav-link btn btn-link buttonAsLinkMenu" onClick={(e) => this.showPage(e, '/staff/employers-list')}>
                Employers
              </a>
            </li>
          )}
          {(
            (adminMenu || bdmEmployerMenu) &&
            <li className={'nav-item ' + (this.checkCurrentRoute('/approve-linked-jobs') ? 'active' : '')}>
              <a role="button" href="/approve-linked-jobs" className="nav-link btn btn-link buttonAsLinkMenu" onClick={(e) => this.showPage(e, '/approve-linked-jobs')}>
                Jobs
              </a>
            </li>
          )}
        </React.Fragment>
      );
    }
    return null;
  }

  private displayStudentMenu() {
    if (this.getState().isUserLoggedIn &&
    Helper.checkActiveUserType(this.props, Constants.STUDENTS_USER_TYPE)) {
      return (
        <React.Fragment>
          <li className={'nav-item ' + (this.checkCurrentRoute('/students/resources/career-pathway') ? 'active' : '')}>
            <a role="button" href="/students/resources/career-pathway" className="nav-link btn btn-link buttonAsLinkMenu" onClick={(e) => this.showPage(e, '/students/resources/career-pathway')}>
              Career Pathway
            </a>
          </li>
          <li className={'nav-item ' + (this.checkCurrentRoute('/employability-skills') ? 'active' : '')}>
            <a role="button" href="/employability-skills" className="nav-link btn btn-link buttonAsLinkMenu" onClick={(e) => this.showPage(e, '/employability-skills')}>
              Employability Skills
            </a>
          </li>
          <li className={'nav-item ' + (this.checkCurrentRoute('/students/jobs-list') ? 'active' : '')}>
            <a role="button" href="/students/jobs-list" className="nav-link btn btn-link buttonAsLinkMenu" onClick={(e) => this.showPage(e, '/students/jobs-list')}>
              Job Alerts
            </a>
          </li>
        </React.Fragment>
      );
    }
    return null;
  }

  private displayCollegeUserMenu() {
    if (this.getState().isUserLoggedIn &&
    Helper.checkActiveUserType(this.props, Constants.COLLEGES_USER_TYPE)) {
      let url = '/colleges/students';
      if (this.hasCollegeAdminPrivileges()) {
        url = '/colleges/students-summary';
      }
      return (
        <React.Fragment>
          {(
            this.showCollegeMenuItem('students') &&
            <li className={'nav-item ' + (this.checkCurrentRoute(url) ? 'active' : '')}>
              <a role="button" href="{url}" className="nav-link btn btn-link buttonAsLinkMenu" onClick={(e) => this.showPage(e, url, { status: 'A' })}>
                Students
              </a>
            </li>
          )}
          {(
            this.showCollegeMenuItem('jobs') &&
            <li className={'nav-item ' + (this.checkCurrentRoute('/approve-linked-jobs') ? 'active' : '')}>
              <a role="button" href="/approve-linked-jobs" className="nav-link btn btn-link buttonAsLinkMenu" onClick={(e) => this.showPage(e, '/approve-linked-jobs', { status: 'A' })}>
                Job Alerts
              </a>
            </li>
          )}
        </React.Fragment>
      );
    }
    return null;
  }

  private displayEmployerUserMenu() {
    if (this.getState().isUserLoggedIn &&
    Helper.checkActiveUserType(this.props, Constants.EMPLOYERS_USER_TYPE)) {
      return (
        <React.Fragment>
          {(
            this.showEmployerMenuItem('jobs') &&
            <li className={'nav-item ' + (this.checkCurrentRoute('/employers/jobs-list') ? 'active' : '')}>
              <a role="button" href="/employers/jobs-list" className="nav-link btn btn-link buttonAsLinkMenu" onClick={(e) => this.showPage(e, '/employers/jobs-list')}>
                Jobs
              </a>
            </li>
          )}
          {(
            this.showEmployerMenuItem('colleges') &&
            <li className={'nav-item ' + (this.checkCurrentRoute('/employers/colleges-list') ? 'active' : '')}>
              <a role="button" href="/employers/colleges-list" className="nav-link btn btn-link buttonAsLinkMenu" onClick={(e) => this.showPage(e, '/employers/colleges-list')}>
                Colleges
              </a>
            </li>
          )}
        </React.Fragment>
      );
    }
    return null;
  }

  private displayDropdownMenu() {
    if (this.getState().isUserLoggedIn) {
      return (
        <React.Fragment>
          <li className={(this.getState().isDropdownMenuOpen ? 'show' : '') + ' nav-item dropdown'}>
            <a role="button" className="nav-link dropdown-toggle btn btn-link buttonAsLinkMenu" onClick={this.toggleDropdownEvent}>
              {this.getUserNameFromProps()}
            </a>
            <div className={(this.getState().isDropdownMenuOpen ? 'show' : '') + ' dropdown-menu dropdown-menu-right'}>
              <span className="dropdown-item">
                <strong>{'User Type: ' + Helper.getAuthorizedUserType(this.props)}</strong>
              </span>
              {(
                !Helper.checkActiveUserType(this.props, Constants.STUDENTS_USER_TYPE) &&
                <React.Fragment>
                  <div className="dropdown-divider"/>
                  <a role="button" href="/account-profile" className="dropdown-item btn btn-link buttonAsLinkMenu" onClick={(e) => this.showPage(e, '/account-profile')}>
                    Account Profile
                  </a>
                </React.Fragment>
              )}
              {(
                Helper.checkActiveUserType(this.props, Constants.STUDENTS_USER_TYPE) &&
                <React.Fragment>
                  <div className="dropdown-divider"/>
                  <a role="button" href="/students/resume-builder" className="dropdown-item btn btn-link buttonAsLinkMenu" onClick={(e) => this.showPage(e, '/students/resume-builder')}>
                    Account Profile
                  </a>
                </React.Fragment>
              )}
              {(
                (
                  Helper.checkActiveUserType(this.props, Constants.EMPLOYERS_USER_TYPE) ||
                  Helper.checkActiveUserType(this.props, Constants.COLLEGES_USER_TYPE) ||
                  Helper.checkActiveUserType(this.props, Constants.STUDENTS_USER_TYPE)
                ) &&
                <React.Fragment>
                  <div className="dropdown-divider"/>
                  <a role="button" href="/notification-preferences" className="dropdown-item btn btn-link buttonAsLinkMenu" onClick={(e) => this.showPage(e, '/notification-preferences')}>
                    Notification Preferences
                  </a>
                </React.Fragment>
              )}
              <div className="dropdown-divider"/>
              <a role="button" href="/update-password" className="dropdown-item btn btn-link buttonAsLinkMenu" onClick={(e) => this.showPage(e, '/update-password')}>
                Update Password
              </a>
              <div className="d-none d-lg-block">
                <div className="dropdown-divider"/>
                <a role="button" href="/signout" className="dropdown-item btn btn-link buttonAsLinkMenu" onClick={(e) => this.signoutEvent(e)}>
                  Signout
                </a>
              </div>
            </div>
          </li>
        </React.Fragment>
      );
    }
    return null;
  }

  private handleDropdownDisplay() {
    if (this.getState().isUserLoggedIn) {
      this.trackMenuEvent();
    }             
  }

  private displayMaintenanceMessage() {
    const maintenanceMessageDetails = this.getState().maintenanceMessageDetails;
    if (maintenanceMessageDetails.type) {
      return (
        <div className="container-fluid maint-msg-bg">
          <div className="container maint-msg text-center">
            {this.getMaintenanceMsg(maintenanceMessageDetails)}
          </div>
        </div>
      );
    }
    return null;
  }

  // events
  private trackMenuEvent = () => {
    // TO DO - recheck code
    document.body.addEventListener('click', (event: any) => {
      const targetElement = event.target;
      const className = targetElement.className as string;
      if (typeof className.includes !== 'undefined') {
        if (!className.includes('dropdown-toggle') && !className.includes('dropdown-item') && !className.includes('fa-bars')) {
          const currState1 = this.getState().isDropdownMenuOpen;
          if (currState1) {
            this.getStore().setDropdownMenuState(false);
          }
          const currState2 = this.getState().isToggleMenuOpen;
          if (currState2) {
            this.getStore().setToggleMenuState(false);
          }
        }
      }
    });
  }

  private toggleNavEvent = () => {
    const currState = this.getState().isToggleMenuOpen;
    this.getStore().setToggleMenuState(!currState);
  }

  private toggleDropdownEvent = () => {
    const currState = this.getState().isDropdownMenuOpen;
    this.getStore().setDropdownMenuState(!currState);
  }

  /* #####overridden method##### */
  showPage(e: React.MouseEvent<any>, url: string, state: object = {}) {
    e.preventDefault();
    e.stopPropagation();
    this.closeMenus();
    if (Object.keys(state).length === 0) {
      this.props.history.push(url);
    } else {
      this.props.history.push({
        pathname: url,
        state
      });
    }
  }
    
  private signoutEvent = (e: React.MouseEvent<any>) => {
    e.preventDefault();
    e.stopPropagation();
    this.closeMenus();
    this.signout();
  }

  // api
  private signout() {
    const serviceInputObj = {
      input: {}
    } as UiTS.RestClientInputArgs;
    
    Service.logout(ServiceInput.prepareInput(this.props, serviceInputObj))
    .then(() => {
      const opts = {};
      if (Helper.checkActiveUserType(this.props, Constants.STUDENTS_USER_TYPE) || Helper.checkActiveUserType(this.props, Constants.COLLEGES_USER_TYPE)) {
        const userDetails = Helper.getAuthorizedUserDetails(this.props) as any;
        if (userDetails && userDetails.college && userDetails.college.url_slug) {
          const custLogin = userDetails.college.user_custom_login || false;
          if (custLogin) {
            opts['custLoginRedirect'] = '/colleges/' + userDetails.college.url_slug;
          }
        }
      }

      Helper.clearAllAndSignout(this.props, false, opts);
    });
  }

  private checkForMaintenanceMessage(setTimer: boolean = false) {
    const serviceInputObj = {
      input: {}
    } as UiTS.RestClientInputArgs;
    MaintMessageService.getMessageDetails(ServiceInput.prepareInput(this.props, serviceInputObj))
    .then((reply: UiTS.MaintenanceMessageDetails) => {
      if (!reply.errorMessage) {
        this.getStore().setMaintenanceMessageState(reply.maintenanceMessageDetails);
      }
      if (setTimer) {
        this.setMaintMsgTimer();
      }
    });
  }

  private sendStudentTaskDetails(taskId: string, completionDate: string) {
    const serviceInputObj = {
      input: {
        pathway_id: taskId,
        completion_date: completionDate
      }
    } as UiTS.RestClientInputArgs;
    TaskService.processPost(ServiceInput.prepareInput(this.props, serviceInputObj))
    .then((reply: UiTS.CareerPathwayStateApi) => {
      if (!reply.errorMessage) {
        const opts = {
          taskId
        };
        Helper.setStudentTasksDetails(this.props, opts, true);
      }
    });
  }

  private sendStudentEmplSkillsDetails(topicId: string, completionDate: string) {
    const serviceInputObj = {
      input: {
        topic_id: topicId,
        completion_date: completionDate
      }
    } as UiTS.RestClientInputArgs;
    EmployabilitySkillsService.processPost(ServiceInput.prepareInput(this.props, serviceInputObj))
    .then((reply: UiTS.EmployabilitySkillsStateApi) => {
      if (!reply.errorMessage) {
        Helper.toggleStudentEmplSkills(this.props, topicId);
      }
    });
  }

  private setSignupPricing() {
    const serviceInputObj = {
      input: {
        action_type: 'signup_pricing'
      }
    } as UiTS.RestClientInputArgs;
    
    MiscDetailsService.getDetails(ServiceInput.prepareInput(this.props, serviceInputObj))
    .then((reply: UiTS.MiscDetailsStateApi) => {
      if (!reply.errorMessage) {
        const miscDetails = reply.miscDetails || {};
        const signupPricing: any = miscDetails.signupPricing || {};
        signupPricing.runDate = Helper.getMomentInstance().unix();
        this.getState().signupPricing = signupPricing;
      }
    });
  }

  // others
  private closeMenus() {
    this.closeDropdown();
    this.closeNav();
  }

  private closeDropdown() {
    this.getStore().setDropdownMenuState(false);
  }

  private closeNav() {
    this.getStore().setToggleMenuState(false);
  }

  // see https://stackoverflow.com/a/43739277
  private handleLoad() {
    // check internet status
    this.setOnlineCheck();

    // check maint. msg
    this.checkForMaintenanceMessage(true);

    // student tasks tracker
    this.trackStudentTasks();

    // student empl skills tracker
    this.trackStudentEmplSkills();

    // check logout event in other tabs
    this.setLogoutEventTimer();
  }

  private setOnlineCheck() {
    isOnline().then((online: boolean) => {
      this.getStore().setInternetCheckState(online);
      if (this.getState().internetChkIntervalId <= 0) {
        this.setOnlineCheckTimer();
      }
    });
  }

  private setOnlineCheckTimer() {
    // tslint:disable:align
    const intervalId = window.setInterval(() => {
      isOnline().then((online: boolean) => {
        this.getStore().setInternetCheckState(online);
      });
    }, 30000);
    this.getStore().setInternetCheckIntervalState(intervalId);
  }

  private setMaintMsgTimer() {
    const intervalId = window.setInterval(() => {
      this.checkForMaintenanceMessage();
    }, 300000);
    this.getStore().setMaintMsgIntervalState(intervalId);
  }

  private getMaintenanceMsg(msgObj: any) {
    const startTime = this.displayDateIST(msgObj.start_time, 'MMM DD, YYYY HH:mm');
    return `The site will be temporarily down for routine maintenance at ${startTime} IST`;
  }

  private trackStudentTasks() {
    const intervalId = window.setInterval(() => {
      this.submitStudentTasks();
    }, 60000);
    this.getStore().setStudentTaskIntervalState(intervalId);
  }

  private trackStudentEmplSkills() {
    const intervalId = window.setInterval(() => {
      this.submitStudentEmplSkills();
    }, 1000);
    this.getStore().setStudentEmplSkillsIntervalState(intervalId);
  }

  private setLogoutEventTimer() {
    const intervalId = window.setInterval(() => {
      this.checkLogoutEvent();
    }, 1000);
    this.getStore().setCheckLogoutIntervalState(intervalId);
  }

  private checkLogoutEvent() {
    const [isLogout, skipUrl] = Helper.watchLogoutEvent();
    if (isLogout) {
      const locHref = window.location.href;
      const flag1 = skipUrl && locHref.endsWith(skipUrl);
      const flag2 = locHref.endsWith('/signin');
      if (!flag1 && !flag2) {
        window.location.href = '/signin';
      }
    }
  }

  private async submitStudentTasks() {
    if (this.getState().isUserLoggedIn &&
    Helper.checkActiveUserType(this.props, Constants.STUDENTS_USER_TYPE)) {
      const userDetails = Helper.getAuthorizedUserDetails(this.props) as any;
      if (userDetails.hasOwnProperty('studentTasks')) {
        const studentTasks = userDetails.studentTasks;
        const keys = Object.keys(studentTasks);
        for (const key of keys) {
          const completionDate = studentTasks[key].completion_date;
          if (completionDate) {
            await this.sendStudentTaskDetails(key, completionDate);
          }
        }
      }
    }
  }

  private async submitStudentEmplSkills() {
    if (this.getState().isUserLoggedIn &&
    Helper.checkActiveUserType(this.props, Constants.STUDENTS_USER_TYPE)) {
      const userDetails = Helper.getAuthorizedUserDetails(this.props) as any;
      if (userDetails.hasOwnProperty('studentEmplSkills')) {
        const studentEmplSkills = userDetails.studentEmplSkills;
        const keys = Object.keys(studentEmplSkills);
        for (const key of keys) {
          const completionDate = studentEmplSkills[key].completion_date;
          const apiSync = studentEmplSkills[key].api_sync || false;
          if (completionDate && !apiSync) {
            await this.sendStudentEmplSkillsDetails(key, completionDate);
          }
        }
      }
    }
  }

  private loadBannerDetails(store: any) {
    const state = store.state;
    const chkProps = state.isUserLoggedIn;
    if (!chkProps) {
      const signupPricing = state.signupPricing || {};
      const len1 = Object.keys(signupPricing).length;
      const studentCollegeInfo = state.studentCollegeInfo || {};
      const len2 = Object.keys(studentCollegeInfo).length;
      if (len1 === 0 || len2 === 0) {
        this.setSignupPricing();
      } else {
        const now = Helper.getMomentInstance().unix();
        const limit = 3600;
        let callNeeded1 = true;
        const chkObj1 = this.getState().studentCollegeInfo || {};
        const chkTS1 = chkObj1.runDate || 0;
        if ((now - chkTS1) < limit) {
          callNeeded1 = false;
        }
        let callNeeded2 = true;
        const chkObj2 = this.getState().signupPricing || {};
        const chkTS2 = chkObj2.runDate || 0;
        if ((now - chkTS2) < limit) {
          callNeeded2 = false;
        }
        if (callNeeded1 || callNeeded2) {
          this.setSignupPricing();
        }
      }
    }
  }
}

export default Header;
