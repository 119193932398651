import * as React from 'react';
import { inject } from 'mobx-react';
import Loadable from 'react-loadable';

import { UiTS } from 'risesharp-common';

import { General } from './../parentClasses/general';
import { Helper } from './../utils/helper';
import PageLoading from './../components/pageLoading';
import Spinner from './../components/spinner';
import Header from './header';
import Content from './content';
import Footer from './footer';

/* #####Components##### */
// loading
const loadComponent = (props: any) => {
  if (props.error) {
    console.log('react loadable error', props.error);
    return (
      <div className="chunk-loader">
        <p className="text-center text-info">
          SORRY! Something is not right
          &nbsp;-&nbsp;
          <a role="button" className="buttonAsLink" onClick={() => (window as any).location.reload(true)}>
            please click here
          </a>
          &nbsp;to reload your browser
        </p>
      </div>
    );
  }
  if (props.pastDelay) {
    return (
      <div className="chunk-loader">
        <p className="text-center text-info">
          Loading...
        </p>
      </div>
    );
  } 
  return (
    <div className="chunk-loader"></div>
  );
};

// general
const Error = Loadable({
  loader: () => import('./../pages/error'),
  loading: loadComponent,
});
const Error404 = Loadable({
  loader: () => import('./../pages/error404'),
  loading: loadComponent,
});
const Home = Loadable({
  loader: () => import('./../pages/home'),
  loading: loadComponent,
});
const AboutUs = Loadable({
  loader: () => import('./../pages/aboutUs'),
  loading: loadComponent,
});
const ContactUs = Loadable({
  loader: () => import('./../pages/contactUs'),
  loading: loadComponent,
});
const TermsOfService = Loadable({
  loader: () => import('./../pages/termsOfService'),
  loading: loadComponent,
});
const PrivacyPolicy = Loadable({
  loader: () => import('./../pages/privacyPolicy'),
  loading: loadComponent,
});
const Signup = Loadable({
  loader: () => import('./../pages/signup'),
  loading: loadComponent,
});
const SignupConfirmation = Loadable({
  loader: () => import('./../pages/signupConfirmation'),
  loading: loadComponent,
});
const ForgotPassword = Loadable({
  loader: () => import('./../pages/forgotPassword'),
  loading: loadComponent,
});
const ChangePassword = Loadable({
  loader: () => import('./../pages/changePassword'),
  loading: loadComponent,
});
const Signin = Loadable({
  loader: () => import('./../pages/signin'),
  loading: loadComponent,
});
const AccountProfile = Loadable({
  loader: () => import('./../pages/accountProfile'),
  loading: loadComponent,
});
const UpdatePassword = Loadable({
  loader: () => import('./../pages/updatePassword'),
  loading: loadComponent,
});
const ApproveLinkedJobs = Loadable({
  loader: () => import('./../pages/approveLinkedJobs'),
  loading: loadComponent,
});
const JobDetails = Loadable({
  loader: () => import('./../pages/jobDetails'),
  loading: loadComponent,
});
const ViewResume = Loadable({
  loader: () => import('./../pages/viewResume'),
  loading: loadComponent,
});
const ContactCareerServices = Loadable({
  loader: () => import('./../pages/contactCareerServices'),
  loading: loadComponent,
});
const TrackAccessUsers = Loadable({
  loader: () => import('./../pages/trackAccessUsers'),
  loading: loadComponent,
});
const ViewCandidates = Loadable({
  loader: () => import('./../pages/viewCandidates'),
  loading: loadComponent,
});
const EventsWorkshops = Loadable({
  loader: () => import('./../pages/eventsWorkshops'),
  loading: loadComponent,
});
const EventsWorkshopsList = Loadable({
  loader: () => import('./../pages/eventsWorkshopsList'),
  loading: loadComponent,
});
const ReferralPartners = Loadable({
  loader: () => import('./../pages/referralPartners'),
  loading: loadComponent,
});
const ViewCandidateSummary = Loadable({
  loader: () => import('./../pages/viewCandidateSummary'),
  loading: loadComponent,
});
const ClaimCollege = Loadable({
  loader: () => import('./../pages/claimCollege'),
  loading: loadComponent,
});
const CreditsUsageReport = Loadable({
  loader: () => import('./../pages/creditsUsageReport'),
  loading: loadComponent,
});
const CollegeMOU = Loadable({
  loader: () => import('./../pages/collegeMOU'),
  loading: loadComponent,
});
const ConfirmClaimCollege = Loadable({
  loader: () => import('./../pages/confirmClaimCollege'),
  loading: loadComponent,
});
const CampusAmbassador = Loadable({
  loader: () => import('./../pages/campusAmbassador'),
  loading: loadComponent,
});
const DirectLogin = Loadable({
  loader: () => import('./../pages/directLogin'),
  loading: loadComponent,
});
const NotificationPreferences = Loadable({
  loader: () => import('./../pages/notificationPreferences'),
  loading: loadComponent,
});
const EmployabilitySkills = Loadable({
  loader: () => import('./../pages/employabilitySkills'),
  loading: loadComponent,
});
const StudyAbroad = Loadable({
  loader: () => import('./../pages/studyAbroad'),
  loading: loadComponent,
});
const UrlShortener = Loadable({
  loader: () => import('./../pages/urlShortener'),
  loading: loadComponent,
});
const PhonepePayment = Loadable({
  loader: () => import('./../pages/phonepePayment'),
  loading: loadComponent,
});
const VerifyCertificate = Loadable({
  loader: () => import('./../pages/verifyCertificate'),
  loading: loadComponent,
});
const MbProxy = Loadable({
  loader: () => import('./../pages/mbProxy'),
  loading: loadComponent,
});

// student
const StudentsGetStarted = Loadable({
  loader: () => import('./../pages/studentsGetStarted'),
  loading: loadComponent,
});
const StudentDashboard = Loadable({
  loader: () => import('./../pages/studentDashboard'),
  loading: loadComponent,
});
const StudentHowTo = Loadable({
  loader: () => import('./../pages/studentHowTo'),
  loading: loadComponent,
});
const StudentJobSearch = Loadable({
  loader: () => import('./../pages/studentJobSearch'),
  loading: loadComponent,
});
const StudentCareerPlanning = Loadable({
  loader: () => import('./../pages/studentCareerPlanning'),
  loading: loadComponent,
});
const StudentCareerChecklist = Loadable({
  loader: () => import('./../pages/studentCareerChecklist'),
  loading: loadComponent,
});
const StudentAssessments = Loadable({
  loader: () => import('./../pages/studentAssessments'),
  loading: loadComponent,
});
const StudentWorkshops = Loadable({
  loader: () => import('./../pages/studentWorkshops'),
  loading: loadComponent,
});
const StudentResourceAppointments = Loadable({
  loader: () => import('./../pages/studentResourceAppointments'),
  loading: loadComponent,
});
const StudentInternships = Loadable({
  loader: () => import('./../pages/studentInternships'),
  loading: loadComponent,
});
const StudentAssessmentsList = Loadable({
  loader: () => import('./../pages/studentAssessmentsList'),
  loading: loadComponent,
});
const PersonalityAssessment = Loadable({
  loader: () => import('./../pages/personalityAssessment'),
  loading: loadComponent,
});
const InterestsAssessment = Loadable({
  loader: () => import('./../pages/interestsAssessment'),
  loading: loadComponent,
});
const ValuesAssessment = Loadable({
  loader: () => import('./../pages/valuesAssessment'),
  loading: loadComponent,
});
const CompetencyAssessment = Loadable({
  loader: () => import('./../pages/competencyAssessment'),
  loading: loadComponent,
});
const StudentJobsList = Loadable({
  loader: () => import('./../pages/studentJobsList'),
  loading: loadComponent,
});
const StudentDressCode = Loadable({
  loader: () => import('./../pages/studentDressCode'),
  loading: loadComponent,
});
const StudentTenTips = Loadable({
  loader: () => import('./../pages/studentTenTips'),
  loading: loadComponent,
});
const StudentResumeMainParts = Loadable({
  loader: () => import('./../pages/studentResumeMainParts'),
  loading: loadComponent,
});
const StudentResumeDosAndDonts = Loadable({
  loader: () => import('./../pages/studentResumeDosAndDonts'),
  loading: loadComponent,
});
const StudentCoverLetter = Loadable({
  loader: () => import('./../pages/studentCoverLetter'),
  loading: loadComponent,
});
const StudentHiredQuickly = Loadable({
  loader: () => import('./../pages/studentHiredQuickly'),
  loading: loadComponent,
});
const StudentNetworkJob = Loadable({
  loader: () => import('./../pages/studentNetworkJob'),
  loading: loadComponent,
});
const StudentFollowUp = Loadable({
  loader: () => import('./../pages/studentFollowUp'),
  loading: loadComponent,
});
const StudentPreRecord = Loadable({
  loader: () => import('./../pages/studentPreRecord'),
  loading: loadComponent,
});
const StudentPhoneInterview = Loadable({
  loader: () => import('./../pages/studentPhoneInterview'),
  loading: loadComponent,
});
const StudentCommonQuestions = Loadable({
  loader: () => import('./../pages/studentCommonQuestions'),
  loading: loadComponent,
});
const StudentCareerFair = Loadable({
  loader: () => import('./../pages/studentCareerFair'),
  loading: loadComponent,
});
const StudentTools = Loadable({
  loader: () => import('./../pages/studentTools'),
  loading: loadComponent,
});
const StudentResumeBuilder = Loadable({
  loader: () => import('./../pages/studentResumeBuilder'),
  loading: loadComponent,
});
const StudentAppointments = Loadable({
  loader: () => import('../pages/studentAppointments'),
  loading: loadComponent,
});
const StudentJobSearchStatus = Loadable({
  loader: () => import('../pages/studentJobSearchStatus'),
  loading: loadComponent,
});
const StudentJobReadiness = Loadable({
  loader: () => import('./../pages/studentJobReadiness'),
  loading: loadComponent,
});
const StudentEmployersList = Loadable({
  loader: () => import('./../pages/studentEmployersList'),
  loading: loadComponent,
});
const CareerPathway = Loadable({
  loader: () => import('./../pages/careerPathway'),
  loading: loadComponent,
});
const StudentApplyJob = Loadable({
  loader: () => import('./../pages/studentApplyJob'),
  loading: loadComponent,
});

// employer
const EmployerDashboard = Loadable({
  loader: () => import('./../pages/employerDashboard'),
  loading: loadComponent,
});
const EmployersCollegesList = Loadable({
  loader: () => import('./../pages/employersCollegesList'),
  loading: loadComponent,
});
const EmployersGetStarted = Loadable({
  loader: () => import('./../pages/employersGetStarted'),
  loading: loadComponent,
});
const EmployerContactUs = Loadable({
  loader: () => import('./../pages/employerContactUs'),
  loading: loadComponent,
});
const EmployerProfile = Loadable({
  loader: () => import('./../pages/employerProfile'),
  loading: loadComponent,
});
const EmployerJob = Loadable({
  loader: () => import('./../pages/employerJob'),
  loading: loadComponent,
});
const EmployerJobsList = Loadable({
  loader: () => import('./../pages/employerJobsList'),
  loading: loadComponent,
});
const EmployerSignup = Loadable({
  loader: () => import('./../pages/employerSignup'),
  loading: loadComponent,
});
const EmployerSignupSuccess = Loadable({
  loader: () => import('./../pages/employerSignupSuccess'),
  loading: loadComponent,
});
const EmployerManageStaff = Loadable({
  loader: () => import('./../pages/employerManageStaff'),
  loading: loadComponent,
});
const EmployerUsageReport = Loadable({
  loader: () => import('./../pages/employerUsageReport'),
  loading: loadComponent,
});
const EmployerCollegeEvents = Loadable({
  loader: () => import('./../pages/employerCollegeEvents'),
  loading: loadComponent,
});

// college
const CollegesGetStarted = Loadable({
  loader: () => import('./../pages/collegesGetStarted'),
  loading: loadComponent,
});
const CollegeContactUs = Loadable({
  loader: () => import('./../pages/collegeContactUs'),
  loading: loadComponent,
});
const CollegeProfile = Loadable({
  loader: () => import('./../pages/collegeProfile'),
  loading: loadComponent,
});
const CollegeDashboard = Loadable({
  loader: () => import('./../pages/collegeDashboard'),
  loading: loadComponent,
});
const CollegeStudents = Loadable({
  loader: () => import('./../pages/collegeStudents'),
  loading: loadComponent,
});
const CollegeApproval = Loadable({
  loader: () => import('./../pages/collegeApproval'),
  loading: loadComponent,
});
const CollegeEmployers = Loadable({
  loader: () => import('./../pages/collegeEmployers'),
  loading: loadComponent,
});
const CollegeJobReadiness = Loadable({
  loader: () => import('./../pages/collegeJobReadiness'),
  loading: loadComponent,
});
const CollegeImportStudents = Loadable({
  loader: () => import('./../pages/collegeImportStudents'),
  loading: loadComponent,
});
const CollegeDirectJob = Loadable({
  loader: () => import('./../pages/collegeDirectJob'),
  loading: loadComponent,
});
const CollegeStudentsSummary = Loadable({
  loader: () => import('./../pages/collegeStudentsSummary'),
  loading: loadComponent,
});
const CollegeInviteEmployers = Loadable({
  loader: () => import('./../pages/collegeInviteEmployers'),
  loading: loadComponent,
});
const ManageCollege = Loadable({
  loader: () => import('./../pages/manageCollege'),
  loading: loadComponent,
});
const CollegeWidgets = Loadable({
  loader: () => import('./../pages/collegeWidgets'),
  loading: loadComponent,
});
const CollegeEnrollmentDetails = Loadable({
  loader: () => import('./../pages/collegeEnrollmentDetails'),
  loading: loadComponent,
});
const CollegeEnrollmentList = Loadable({
  loader: () => import('./../pages/collegeEnrollmentList'),
  loading: loadComponent,
});
const CollegeManageStaff = Loadable({
  loader: () => import('./../pages/collegeManageStaff'),
  loading: loadComponent,
});
const MentoringNotes = Loadable({
  loader: () => import('./../pages/mentoringNotes'),
  loading: loadComponent,
});
const JobPlacementReport = Loadable({
  loader: () => import('./../pages/jobPlacementReport'),
  loading: loadComponent,
});
const JobPlacementSummary = Loadable({
  loader: () => import('./../pages/jobPlacementSummary'),
  loading: loadComponent,
});

// staff
const StaffDashboard = Loadable({
  loader: () => import('./../pages/staffDashboard'),
  loading: loadComponent,
});
const StaffCollegesList = Loadable({
  loader: () => import('./../pages/staffCollegesList'),
  loading: loadComponent,
});
const StaffCollege = Loadable({
  loader: () => import('./../pages/staffCollege'),
  loading: loadComponent,
});
const StaffEmployersList = Loadable({
  loader: () => import('./../pages/staffEmployersList'),
  loading: loadComponent,
});
const StaffEmployer = Loadable({
  loader: () => import('./../pages/staffEmployer'),
  loading: loadComponent,
});
const SupportIssues = Loadable({
  loader: () => import('./../pages/supportIssues'),
  loading: loadComponent,
});
const StaffNotifyStudents = Loadable({
  loader: () => import('./../pages/staffNotifyStudents'),
  loading: loadComponent,
});
const StaffNotifyTpos = Loadable({
  loader: () => import('./../pages/staffNotifyTpos'),
  loading: loadComponent,
});
const StaffManageUsers = Loadable({
  loader: () => import('./../pages/staffManageUsers'),
  loading: loadComponent,
});
const TrackInvoicesReport = Loadable({
  loader: () => import('./../pages/trackInvoicesReport'),
  loading: loadComponent,
});
const CollegeDegreeDetails = Loadable({
  loader: () => import('./../pages/collegeDegreeDetails'),
  loading: loadComponent,
});
const EmployerSubscription = Loadable({
  loader: () => import('./../pages/employerSubscription'),
  loading: loadComponent,
});
const MakePayment = Loadable({
  loader: () => import('./../pages/makePayment'),
  loading: loadComponent,
});

/* #####Components##### */

// required for checking unauthorized access
@inject('store')
class Container extends General {
  constructor(props: React.Props<any>) {
    super(props);
    this.state = {
      retVal: 0
    };
  }

  // tslint:disable-next-line
  UNSAFE_componentWillMount() {
    // when url entered directly in browser
    const checkPath = '/direct-link';
    const currentRoute = this.props.location.pathname;
    // console.log('currentRoute cwm', currentRoute);
    const skipFlag = (currentRoute.indexOf(checkPath) !== -1);
    if (!skipFlag) {
      const isSecure = this.props.secure;
      const userTypes = this.props.allowedUserTypes;
      const tokenOK = Helper.onLoadCheckToken();
      const userType = Helper.onLoadGetUserType();

      const retVal = this.checkUserAccess(isSecure, tokenOK, userTypes, userType, 'CWM');
      this.setState({ retVal });

      if (retVal > 0) {
        /*console.log('cwm/1', retVal);
        console.log('cwm/2', isSecure);
        console.log('cwm/3', tokenOK);
        console.log('cwm/4', userTypes);
        console.log('cwm/5', userType);
        console.log('cwm/6', (this.props as any).match);*/
        const pathName = this.props.location.pathname;
        const hash = this.props.location.hash;
        const queryParams = this.props.location.search;
        let redirectTo = pathName;
        if (hash) {
          redirectTo += hash;
        }
        if (queryParams) {
          redirectTo += queryParams;
        }
        // console.log('cwm', redirectTo);
        Helper.clearAllAndSignout(this.props, (retVal === 2), { redirectTo, replaceLocation: true });
      }
    }
  }

  // tslint:disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps: UiTS.CustomProps) {
    // when using push via react-router component
    const checkPath = '/direct-link';
    const currentRoute = (nextProps as any).location.pathname;
    // console.log('currentRoute cwrp', currentRoute);
    const skipFlag = (currentRoute.indexOf(checkPath) !== -1);
    if (!skipFlag) {
      const isSecure = nextProps.secure!;
      const userTypes = nextProps.allowedUserTypes!;
      const tokenOK = Helper.getAuthorizedToken(nextProps) !== '';
      const userType = Helper.getAuthorizedUserType(nextProps);

      const retVal = this.checkUserAccess(isSecure, tokenOK, userTypes, userType, 'CWRP');
      this.setState({ retVal });

      if (retVal > 0) {
        /*console.log('cwrp/1', retVal);
        console.log('cwrp/2', isSecure);
        console.log('cwrp/3', tokenOK);
        console.log('cwrp/4', userTypes);
        console.log('cwrp/5', userType);
        console.log('cwrp/6', (nextProps as any).match);*/
        const pathName = (nextProps as any).location.pathname;
        const hash = (nextProps as any).location.hash;
        const queryParams = (nextProps as any).location.search;
        let redirectTo = pathName;
        if (hash) {
          redirectTo += hash;
        }
        if (queryParams) {
          redirectTo += queryParams;
        }
        // console.log('cwrp', redirectTo);
        Helper.clearAllAndSignout(nextProps, (retVal === 2), { redirectTo, replaceLocation: true });
      }
    }
  }

  render() {
    if (this.state.retVal > 0) {
      return null;
    }

    // @ts-ignore:isSecure,userTypes
    const [componentName, isSecure, userTypes, props] = this.getComponentName();
    return this.getDefaultLayout(componentName, props);
  }

  private getComponentName(): [string, boolean, string[], React.Props<any>] {
    const props: UiTS.CustomProps = Object.assign({}, this.props);
    const componentName: string = props.componentName!;
    const secure: boolean = props.secure!;
    const userTypes = props.allowedUserTypes!;
    delete props.componentName;
    delete props.secure;
    delete props.allowedUserTypes;
    return [componentName, secure, userTypes, props];
  }

  private getDefaultLayout(componentName: string, props: React.Props<any>) {
    const chkUserAgent = 'GetJobReady Mobile App RNW / 1.0'; // should be same UA as set in mobile app
    const userAgent = window.navigator.userAgent;
    let hideHeaderAndFooter = false;
    if (userAgent === chkUserAgent) {
      hideHeaderAndFooter = true;
      setTimeout(() => { this.adjustLayoutForRNW(); }, 100);
    }
    const newProps = Object.assign({}, props, { hideHeaderAndFooter });
    return (
      <div id="app-wrapper">
        <div id="app-header" className="container-fluid header">
          <Header {...newProps}/>
        </div>
        <div id="app-content" className="container-fluid content">
          {this.loadPageLoadingIndicator()}
          <Content {...newProps}>
            {this.getDefaultLayoutChildren(componentName, newProps)}
          </Content>
        </div>
        <div id="app-footer" className="container-fluid footer">
          <Footer {...newProps}/>
        </div>
      </div>
    );
  }
  private adjustLayoutForRNW() {
    const tags = document.getElementsByTagName('body');
    if (tags && tags.length === 1) {
      tags[0].setAttribute('style', 'margin-bottom:0px !important');
    }
    const footer = document.getElementsByClassName('container-fluid footer');
    if (footer && footer.length === 1) {
      footer[0].setAttribute('style', 'height:0px !important');
    }
  }

  private getDefaultLayoutChildren(componentName: string, props: React.Props<any>): JSX.Element {
    switch (componentName) {
      /* general */
      case 'Error':
        return <Error {...props}/>;
      case 'Home':
        return <Home {...props}/>;
      case 'AboutUs':
        return <AboutUs {...props}/>;
      case 'ContactUs':
        return <ContactUs {...props}/>;
      case 'PrivacyPolicy':
        return <PrivacyPolicy{...props}/>;
      case 'TermsOfService':
        return <TermsOfService {...props}/>;
      case 'Signup':
        return <Signup {...props}/>;
      case 'SignupConfirmation':
        return <SignupConfirmation {...props}/>;
      case 'ForgotPassword':
        return <ForgotPassword {...props}/>;
      case 'ChangePassword':
        return <ChangePassword {...props}/>;
      case 'Signin':
        return <Signin {...props}/>;
      case 'AccountProfile':
        return <AccountProfile {...props}/>;
      case 'UpdatePassword':
        return <UpdatePassword {...props}/>;
      case 'ApproveLinkedJobs':
        return <ApproveLinkedJobs {...props}/>;
      case 'JobDetails':
        return <JobDetails {...props}/>;
      case 'ViewResume':
        return <ViewResume {...props}/>;
      case 'ContactCareerServices':
        return <ContactCareerServices {...props}/>;
      case 'TrackAccessUsers':
        return <TrackAccessUsers {...props}/>;
      case 'ViewCandidates':
        return <ViewCandidates {...props}/>;
      case 'EventsWorkshops':
        return <EventsWorkshops {...props}/>;
      case 'EventsWorkshopsList':
        return <EventsWorkshopsList {...props}/>;
      case 'ReferralPartners':
        return <ReferralPartners {...props}/>;
      case 'ViewCandidateSummary':
        return <ViewCandidateSummary {...props}/>;
      case 'ClaimCollege':
        return <ClaimCollege {...props}/>;
      case 'CreditsUsageReport':
        return <CreditsUsageReport {...props}/>;
      case 'CollegeMOU':
        return <CollegeMOU {...props}/>;
      case 'ConfirmClaimCollege':
        return <ConfirmClaimCollege {...props}/>;
      case 'CampusAmbassador':
        return <CampusAmbassador {...props}/>;
      case 'DirectLogin':
        return <DirectLogin {...props}/>;
      case 'NotificationPreferences':
        return <NotificationPreferences {...props}/>;
      case 'EmployabilitySkills':
        return <EmployabilitySkills {...props}/>;
      case 'StudyAbroad':
        return <StudyAbroad {...props}/>;
      case 'MakePayment':
        return <MakePayment {...props}/>;
      case 'UrlShortener':
        return <UrlShortener {...props}/>;
      case 'PhonepePayment':
        return <PhonepePayment {...props}/>;
      case 'VerifyCertificate':
        return <VerifyCertificate {...props}/>;
      case 'MbProxy':
        return <MbProxy {...props}/>;
  
      /* student */
      case 'StudentDashboard':
        return <StudentDashboard {...props}/>;
      case 'StudentsGetStarted':
        return <StudentsGetStarted {...props}/>;
      case 'StudentResources':
      case 'StudentCareerPlanning':
        return <StudentCareerPlanning {...props}/>;
      case 'StudentCareerChecklist':
        return <StudentCareerChecklist {...props}/>;
      case 'StudentHowTo':
        return <StudentHowTo {...props}/>;
      case 'StudentJobSearch':
        return <StudentJobSearch {...props}/>;
      case 'StudentAssessments':
        return <StudentAssessments {...props}/>;
      case 'StudentWorkshops':
        return <StudentWorkshops {...props}/>;
      case 'StudentResourceAppointments':
        return <StudentResourceAppointments {...props}/>;
      case 'StudentInternships':
        return <StudentInternships {...props}/>;
      case 'StudentHiredQuickly':
        return <StudentHiredQuickly {...props}/>;
      case 'StudentResumeMainParts':
        return <StudentResumeMainParts {...props}/>;
      case 'StudentResumeDosAndDonts':
        return <StudentResumeDosAndDonts {...props}/>;
      case 'StudentCoverLetter':
        return <StudentCoverLetter {...props}/>;
      case 'StudentAssessmentsList':
        return <StudentAssessmentsList {...props}/>;
      case 'PersonalityAssessment':
        return <PersonalityAssessment {...props}/>;
      case 'InterestsAssessment':
        return <InterestsAssessment {...props}/>;
      case 'ValuesAssessment':
        return <ValuesAssessment {...props}/>;
      case 'CompetencyAssessment':
        return <CompetencyAssessment {...props}/>;
      case 'StudentJobsList':
        return <StudentJobsList {...props}/>;
      case 'StudentDressCode':
        return <StudentDressCode {...props}/>;
      case 'StudentTenTips':
        return <StudentTenTips {...props}/>;
      case 'StudentNetworkJob':
        return <StudentNetworkJob {...props}/>;
      case 'StudentFollowUp':
        return <StudentFollowUp {...props}/>;
      case 'StudentPreRecord':
        return <StudentPreRecord {...props}/>;
      case 'StudentPhoneInterview':
        return <StudentPhoneInterview {...props}/>;
      case 'StudentCommonQuestions':
        return <StudentCommonQuestions {...props}/>;
      case 'StudentCareerFair':
        return <StudentCareerFair {...props}/>;
      case 'StudentTools':
        return <StudentTools {...props}/>;
      case 'StudentResumeBuilder':
        return <StudentResumeBuilder {...props}/>;
      case 'StudentAppointments':
        return <StudentAppointments {...props}/>;
      case 'StudentJobSearchStatus':
        return <StudentJobSearchStatus {...props}/>;
      case 'StudentJobReadiness':
        return <StudentJobReadiness {...props}/>;
      case 'StudentEmployersList':
        return <StudentEmployersList {...props}/>;
      case 'CareerPathway':
        return <CareerPathway {...props}/>;
      case 'StudentApplyJob':
        return <StudentApplyJob {...props}/>;

      /* employer */
      case 'EmployerDashboard':
        return <EmployerDashboard {...props}/>;
      case 'EmployersCollegesList':
        return <EmployersCollegesList {...props}/>;
      case 'EmployersGetStarted':
        return <EmployersGetStarted {...props}/>;
      case 'EmployerContactUs':
        return <EmployerContactUs {...props}/>;
      case 'EmployerProfile':
        return <EmployerProfile {...props}/>;
      case 'EmployerJob':
        return <EmployerJob {...props}/>;
      case 'EmployerJobsList':
        return <EmployerJobsList {...props}/>;
      case 'EmployerSignup':
        return <EmployerSignup {...props}/>;
      case 'EmployerSignupSuccess':
        return <EmployerSignupSuccess {...props}/>;
      case 'EmployerManageStaff':
        return <EmployerManageStaff {...props}/>;
      case 'EmployerUsageReport':
        return <EmployerUsageReport {...props}/>;
      case 'EmployerCollegeEvents':
        return <EmployerCollegeEvents {...props}/>;

      /* college */
      case 'CollegesGetStarted':
        return <CollegesGetStarted {...props}/>;
      case 'CollegeContactUs':
        return <CollegeContactUs {...props}/>;
      case 'CollegeProfile':
        return <CollegeProfile {...props}/>;
      case 'CollegeDashboard':
        return <CollegeDashboard {...props}/>;
      case 'CollegeStudents':
        return <CollegeStudents {...props}/>;
      case 'CollegeApproval':
        return <CollegeApproval {...props}/>;
      case 'CollegeEmployers':
        return <CollegeEmployers {...props}/>;
      case 'CollegeJobReadiness':
        return <CollegeJobReadiness {...props}/>;
      case 'CollegeImportStudents':
        return <CollegeImportStudents {...props}/>;
      case 'CollegeDirectJob':
        return <CollegeDirectJob {...props}/>;
      case 'CollegeStudentsSummary':
        return <CollegeStudentsSummary {...props}/>;
      case 'CollegeInviteEmployers':
        return <CollegeInviteEmployers {...props}/>;
      case 'ManageCollege':
        return <ManageCollege {...props}/>;
      case 'CollegeWidgets':
        return <CollegeWidgets {...props}/>;
      case 'CollegeEnrollmentDetails':
        return <CollegeEnrollmentDetails {...props}/>;
      case 'CollegeEnrollmentList':
        return <CollegeEnrollmentList {...props}/>;
      case 'CollegeManageStaff':
        return <CollegeManageStaff {...props}/>;
      case 'MentoringNotes':
        return <MentoringNotes {...props}/>;
      case 'JobPlacementReport':
        return <JobPlacementReport {...props}/>;
      case 'JobPlacementSummary':
        return <JobPlacementSummary {...props}/>;

      /* staff */
      case 'StaffDashboard':
        return <StaffDashboard {...props}/>;
      case 'StaffCollegesList':
        return <StaffCollegesList {...props}/>;
      case 'StaffCollege':
        return <StaffCollege {...props}/>;
      case 'StaffEmployersList':
        return <StaffEmployersList {...props}/>;
      case 'StaffEmployer':
        return <StaffEmployer {...props}/>;
      case 'SupportIssues':
        return <SupportIssues {...props}/>;
      case 'StaffNotifyStudents':
        return <StaffNotifyStudents {...props}/>;
      case 'StaffNotifyTpos':
        return <StaffNotifyTpos {...props}/>;
      case 'StaffManageUsers':
        return <StaffManageUsers {...props}/>;
      case 'TrackInvoicesReport':
        return <TrackInvoicesReport {...props}/>;
      case 'CollegeDegreeDetails':
        return <CollegeDegreeDetails {...props}/>;
      case 'EmployerSubscription':
        return <EmployerSubscription {...props}/>;
      
      /* default */
      case 'Error404':
      default:
        return <Error404 {...props}/>;
    }
  }

  private checkUserAccess(isSecure: boolean, tokenOK: boolean, userTypes: string[], userType: string, logIdentifier: string) {
    let retVal = 0;
    if (isSecure && !tokenOK) {
      console.log(`${logIdentifier}- unauthorized access for authenticated route`);
      retVal = 1;
    }
    
    if (retVal === 0 && isSecure && tokenOK) {
      if (userTypes && userTypes.length > 0) {
        if (userTypes.indexOf(userType) === -1) {
          console.log(`${logIdentifier}- unauthorized user type access for authenticated route`);
          retVal = 2;
        }
      }
    }
    return retVal;
  }

  private loadPageLoadingIndicator() {
    const spinner = <Spinner display={true} iconType="component-mount"/>;
    return (
      <PageLoading spinner={spinner}/>
    );
  }
}

export default Container;
